// import React from 'react'
// import CustomButton from '../Commoncomponents/button'
// import { Typography } from '@mui/material'

// function TemplateElements() {
//   return (
//     <>
// <div style={{marginTop:"39px",width:"100%",height:"100px",background:"#D9D9D9",display:"flex",justifyContent:"center",alignItems:"center", gap:"30px"}}>
//         <div style={{display:"flex",justifyContent:"space-evenly",flexDirection:"row",alignItems:"center",alignContent:"center",gap:"50px"}}>
//                <div>
//                 <CustomButton background="#0D75BA">
//                   <Typography sx={{textTransform:"capitalize"}}>
//                   Body
//                   </Typography>
//                 </CustomButton>
//                </div>
//                <div>
//                <CustomButton background="#0D75BA" width="100px">
//                <Typography sx={{textTransform:"capitalize"}}>
//                   Buttons
//                   </Typography>
//                 </CustomButton>
//                </div>
//                <div>
//                <CustomButton background="#0D75BA">
//                <Typography sx={{textTransform:"capitalize"}}>
//                   Footer
//                   </Typography>
//                 </CustomButton>
//                </div>
//         </div>
//     </div>
//         </>
//   )
// }

// export default TemplateElements

import React, { useEffect, useState } from "react";
import {
  Divider,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  TextField,
  DialogTitle,
  DialogContent,
  tableBodyClasses,
  DialogActions,
  Grid,
  MenuItem,
  Select,
  Menu,
  Dialog,
  RadioGroup,
} from "@mui/material";
import Line from "../../Utils/Images/Line.svg";
import {
  contentFontSize_16,
  contentFontsize_30,
  contentFontsize_14,
  contentFontSize_16_xs_10,
} from "../Typography";
import Rectangle from "../../Utils/Images/Rectangle.svg";
import Edit from "../../Utils/Images/Edit.svg";
import Delete from "../../Utils/Images/Delete.svg";
//import TemplateForm from "./TemplateForm";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useLocation, useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import Smiley from "../../Utils/Images/Smiley.svg";
import Bold from "../../Utils/Images/Bold.svg";
import Italics from "../../Utils/Images/Italics.svg";
import Plus from "../../Utils/Images/Plus.svg";
import Food from "../../Utils/Images/Food.svg";
import CustomButton from "../Commoncomponents/button/index";
import CustomDialog from "../Commoncomponents/dialog/index";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CustomCard from "../Commoncomponents/card";
import "./TemplateElements.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTemplateDelete,
  getTemplateList,
  getTemplateSave,
} from "../../graphql/actions";
import { setDeleteTemplateSuccess } from "../../redux/slices/marketingSlice";
import { message } from "antd";
export default function TemplateElements() {
  const theme = useTheme();

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const uplgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const steps = [
    "Create New Template",
    "Add Template Elements",
    "Preview Template",
  ];
  const [form, setForm] = useState(false);
  const nav = useNavigate();
  // function handleCreate(){
  //   // setForm(true)
  //    nav("/template/templateForm")
  // }
  useEffect(() => {
    dispatch(getTemplateList());
  }, []);
  const isLessThan350 = useMediaQuery("(max-width:350px)");
  const isLessThan540 = useMediaQuery("(max-width:540px)");
  const smMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const mdMatch = useMediaQuery(theme.breakpoints.down("md"));
  const lgMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const [text, setText] = useState("");

  function handleText(event) {
    setMedia("");
    setText(event.target.value);
    console.log("text", text);
  }
  const [media, setMedia] = useState("");
  function handleMedia(event) {
    setText("");
    setMedia(event.target.value);
  }
  const [next, setNext] = useState(false);

  // function handleNext() {
  //   nav("/template/templateElements");
  // }
  const [open, setOpen] = useState(false);
  function handleBody() {
    setOpen(true);
  }
  function handleClose() {
    //setActive("cancel");
    setOpen(false);
    setButton(false);
    setFooter(false);
    setDialogOpen(false);
    
  }
  const [button, setButton] = useState(false);
  function handleButtons() {
    setButton(true);
  }
 
  const [footer, setFooter] = useState(false);
  function handleFooter() {
    setFooter(true);
  }
  const [tempChange, setTempChange] = useState('');
  const [change, setChange] = useState("");
  // function handleChange(event) {
  //   setChange(event.target.value);
  //   console.log("change", change);
  // }
  const [add, setAdd] = useState(false);
  function handleAdd() {
    setChange(tempChange)
    setAdd(true);
    //console.log("add",add)
    setOpen(false);
  }
  console.log("change",change)
  const [checkedLabels, setCheckedLabels] = useState([]);
  const [labels, setLabels] = useState("");
  const [selectedLabel, setSelectedLabel] = useState(null); // State to track the selected label

  const handleCheckBox = (event) => {
    const label = event.target.labels[0].textContent;
    const { checked } = event.target;
    console.log("labellll", selectedLabel);
    if (checked) {
      setSelectedLabel(label); // Update the state with the new selected label
    }
  };

  const [apply, setApply] = useState(false);
  function handleApply() {
    console.log("label", labels);
    console.log("hiii");
    setApply(true);
    setButton(false);
  }
  const [footerText, setFooterText] = useState("Marketing Team Nearshopz");

  const [one, setOne] = useState(false);
  function handleFooterOne() {
    setOne(true);
    setFooter(false);
  }
  const [dialogOpen, setDialogOpen] = useState(false);
  function handleModalOpen() {
    setDialogOpen(true);
  }

  // const [open, setOpen] = useState(false);
  // const [change, setChange] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    setTempChange((prevValue) => `${prevValue} ${option}`);
    handleMenuClose();
  };

  const handleTextFieldChange = (event) => {
    setTempChange(event.target.value); // Update tempChange instead of change
  };

  //const [text, setText] = useState('');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const toggleBold = () => {
    setIsBold(!isBold);
  };

  const toggleItalic = () => {
    setIsItalic(!isItalic);
  };
  

  const [selectedPair, setSelectedPair] = useState('');
  
  const radioLabels = {
    pair1: "Interested / Not Interested",
    pair2: "View Website / Call Now",
    pair3: "Get Started / Learn More",
    pair4: "Subscribe / No Thanks",
    pair5: "Shop Now / View Details",
    pair6: "Claim Offer / Skip Offer",
    pair7: "Schedule a Call / Send a Message",
    pair8: "Download App / Use Web Version",
    pair9: "Explore Products / Request Info",
    pair10: "Apply Now / Request More Info",
  };
  const [selectedLabels,setSelectedLabels] = useState('')  
  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedLabels(radioLabels[selectedValue]); // Get the label based on the value
  
    setSelectedPair(selectedValue);
    console.log("Selected value:", selectedValue);
    console.log("Selected label:", selectedLabel); // Store the label in a variable
  };
  // const applyFormatting = (tag) => {
  //   const textarea = document.getElementById('textField');
  //   const { selectionStart, selectionEnd, value } = textarea;
  //   const selectedText = value.substring(selectionStart, selectionEnd);

  //   // Wrap selected text with the chosen tag
  //   const newText = `${value.substring(0, selectionStart)}<${tag}>${selectedText}</${tag}>${value.substring(selectionEnd)}`;

  //   setChange(newText);
  // };
  const listData = useSelector((state) => state.marketingSlice?.listData);
  console.log("data", listData?.listTemplates);
  const [deletes, setDeletes] = useState(false);
  const deleteData = useSelector((state) => state.marketingSlice?.deleteData);
  // const [deletes, setDeletes] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null); // For tracking the item to delete

  const handleDialog = (id) => {
    setSelectedItemId(id); // Set the ID of the item you want to delete
    setDeletes(true); // Open the dialog
  };
  // function handleClose() {
  //   setDeletes(false);
  // }
  function handleDeletes(id) {
    dispatch(getTemplateDelete(id));

    setDeletes(false);
  }
  const deleteTemplateSuccess = useSelector(
    (state) => state.marketingSlice.deleteTemplateSuccess
  );
  console.log("success", deleteTemplateSuccess);

  useEffect(() => {
    if (deleteTemplateSuccess) {
      setDeletes(false);
      message.success({
        content: "Template Deleted Successfully",
        className: "custom-success-message",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 1.5,
      });

      setTimeout(() => {
        // Reload the list or perform any other actions
        dispatch(setDeleteTemplateSuccess());
        dispatch(getTemplateList());
        // Reset the success flag after handling it
      }, 1500);
    }
  }, [deleteTemplateSuccess]);

  console.log("deleteData", deleteData);

  const leftSideContent = (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        position: "relative",
        zIndex: 0,
        padding: "20px",
        minHeight: "180vh",
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          fontFamily: "Inter",
          fontSize: { xs: "14px", sm: "16px", md: "20px", lg: "22px" },
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        Available Template
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: smMatch ? "60%" : mdMatch ? "130px" : "300px",
            marginTop: isLessThan350 ? "30px" : "120px",
            textAlign: "center",
            textTransform: "capitalize",
            height: "50px",
            borderRadius: "10px",
          }}
        >
          Create New Template
        </Button>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: mdMatch ? "column" : "row",
          justifyContent: mdMatch ? "center" : "space-evenly",
          marginTop: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CustomCard width="30px" height="40px" />
          <Typography style={{ textAlign: "center", marginTop: "10px" }}>
            Welcome
          </Typography>
        </div>
        <div
          style={{
            marginTop: mdMatch ? "20px" : "0",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={Edit} style={{ cursor: "pointer" }} />
          <img src={Delete} style={{ cursor: "pointer", marginLeft: "20px" }} />
        </div>
      </div> */}
       {listData?.listTemplates?.map((item) => (
  <div
    key={item.id}
    style={{
      display: "flex",
      flexDirection: lgMatch ? "column" : "row",
      justifyContent: mdMatch ? "center" : "space-evenly",
      marginTop: "20px",
      alignItems: "center",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "40px",
      }}
    >
      <>
        <CustomCard width="170px" height="100%" padding="10px 30px 80px 10px">
          <div>
            <Typography>{item?.header?.content}</Typography>
          </div>
          <div>
            <Typography>{item?.body}</Typography>
          </div>
          <div style={{ marginTop: "15px" }}>
            <CustomButton background="#0D75BA">
              <Typography style={{ textTransform: "capitalize" }}>
                {item.button}
              </Typography>
            </CustomButton>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Typography>{item.footer}</Typography>
          </div>
        </CustomCard>

        <Typography style={{ textAlign: "center", marginTop: "10px" }}>
          {item.templateName}
        </Typography>
      </>
    </div>
    <div
      style={{
        marginTop: lgMatch ? "20px" : "0",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={Edit}
        style={{ cursor: "pointer" }}
        onClick={() => handleEdit(item.id)} // Pass the id onClick
      />
      <img
        src={Delete}
        style={{ cursor: "pointer", marginLeft: "20px" }}
        onClick={() => handleDialog(item.id)} // Open the dialog with item ID
      />
    </div>
  </div>
))}


{deletes && selectedItemId && (
  // <CustomDialog
  //   open={deletes}
  //   onClose={handleClose}
  //   top="20px"
  //   right="20px"
  //   width="400px"
  //   height="600px"
  //   borderRadius="25px"
  //   BackdropProps={{
  //     style: {
  //       backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity or color here
  //     },
  //   }}
  // >
  //   <DialogContent>
  //     <Typography sx={{ color: "black", fontWeight: "400", fontSize: "18px" }}>
  //       Are you sure you want to delete this Template?
  //     </Typography>
  //   </DialogContent>
  //   <DialogActions>
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         marginRight: "70px",
  //         gap: "60px",
  //       }}
  //     >
  //       <div>
  //         <CustomButton
  //           onClick={() => handleDeletes(selectedItemId)} // Delete the selected item
  //           color="white"
  //           background="#0D75BA"
  //         >
  //           Yes
  //         </CustomButton>
  //       </div>
  //       <div>
  //         <CustomButton onClick={handleClose} color="white" background="#0D75BA">
  //           No
  //         </CustomButton>
  //       </div>
  //     </div>
  //   </DialogActions>
  // </CustomDialog>
  <Dialog
          open={deletes}
        >
          <DialogContent>
            <div style={{ height: "100%", width: "100%", padding: mdMatch ? "10px" : "15px" }}>
              <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                <Typography fontSize={contentFontSize_16_xs_10} className='chatFontHeebo400Color'>Are you sure you want to delete this Template?</Typography>

                <div style={{ display: "flex", gap: mdMatch ? "10px" : "15px", textAlign: "center", justifyContent: "space-evenly" }}>
                  <Typography fontSize={contentFontSize_16_xs_10} onClick={handleClose} className='chatFontHeebo400Color' style={{ cursor: "pointer" }}>Cancel</Typography>
                  <Typography fontSize={contentFontSize_16_xs_10}>|</Typography>
                  <Typography fontSize={contentFontSize_16_xs_10} onClick={() => handleDelete(selectedItemId)}  className='chatFontHeebo400Red' style={{ cursor: "pointer" }}>Delete</Typography>
                </div>

              </div>

            </div>
          </DialogContent>
        </Dialog>
)}
    </div>
    
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const value = location.state?.value;
  const option = location.state?.option;
  
  const previousSave = location?.state?.previousSave
  console.log("previousSave",previousSave)
  const previousNext = location?.state?.previousNext
  console.log("previousNext",previousNext)
  console.log("option", option);
  console.log("hehe", value);
  const content = [{body:change,button:selectedLabels,footer:footerText}]
  //const [array,setArray] = useState(content)
  //console.log("arrayy",array)
  function handleNext() {
  // Convert the array to a string before storing it in localStorage
    

    const templateName = value?.templateName;
    const language = value?.language;
    const headerType = value?.messageText ? "text" : "file";
    const headerContent = value?.messageText ? value?.messageText : " ";
    const headerVariable = option;
    const headerVariableType = headerVariable ? "String" : "String";
    const body = change;
    const bodyName = selectedOption;
    const bodyType = selectedOption ? "String" : "";
    const button = selectedLabels;
    const footer = footerText;
    dispatch(
      getTemplateSave(
        templateName,
        language,
        headerType,
        headerContent,
        headerVariable,
        headerVariableType,
        body,
        bodyName,
        bodyType,
        button,
        footer
      )
    );
    localStorage.setItem("array", JSON.stringify(content));
    //localStorage.setItem("arrayValues", dataArray);
    nav("/template/templateSave");
  }
  // const storedArray = JSON.parse(localStorage.getItem("array"));
  console.log("storedArray",previousSave)
  const dataArray = [
    {
      key: "templateName",
      value: value?.templateName,
    },
    {
      key: "language",
      value: value?.language,
    },
    {
      key: "header",
      type: value?.messageText ? "text" : "file",
      content: value?.messageText ? value?.messageText : " ",
      variable: option,
      variableType: "String",
    },
    {
      key: "body",
      content: change,
      name: selectedOption,
      type: "String",
    },
    {
      key: "button",
      value: selectedLabel,
    },
    {
      key: "footer",
      value: footerText,
    },
  ];
  
//   const loadSavedValues = () => {
//     const savedValues = localStorage.getItem('array');
//     return savedValues && !previousSave ? JSON.parse(savedValues) : null;
//   };

//   //const [array, setArray] = useState(loadSavedValues);
//   //console.log("loadSavedValues",loadSavedValues)
//   useEffect(() => {
    
//     setArray(loadSavedValues())
  
//   }, []);

// useEffect(()=>{
//   setArray("") 
//  //localStorage.removeItem('formValues')
// },[])

  const [previous, setPrevious] = useState(false);
  console.log("dataArray", dataArray);
  function handlePrevious() {
    setPrevious(true);
    nav("/template/templateForm", {
      state: { previous: previous },
    });
  }
  // {!previousSave && (
  //   setChange(localStorage.getItem('array')[0].body)

  // )}
  const mediaSrc = localStorage.getItem("media");
  console.log("Media Source:", mediaSrc);

  const handleEdit = () => {
    setTempChange(change); // Set tempChange to current change value
    setOpen(true);
  };
  function handleEditButton() {
    setButton(true);
  }
  function handleEditFooter() {
    setFooter(true);
  }

  function handleDelete() {
    setAdd(false);
    // setApply(false)
    // setOne(false)
  }
  function handleDelete1() {
    //setAdd(false)
    setApply(false);
    // setOne(false)
  }
  function handleDelete2() {
    //setAdd(false)
    // setApply(false)
    setOne(false);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const listData = useSelector((state) => state.marketingSlice?.listData);
  // console.log("data", listData?.listTemplates);
  
  // useEffect(()=>{
  //    setArray("")
  // },[])


  return (
    <>
      <div>
        <Typography
          sx={{ fontWeight: "600", fontFamily: "Inter", fontSize: "28px" }}
        >
          Template
        </Typography>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            border: "1px solid #ccc",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 25px 5px",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "125px",
              left: 0,
              right: 0,
              borderBottom: "1px solid #7C7B7B",
              zIndex: 1,
              flexGrow: 0,
              flexShrink: 0,
              maxHeight: "60px", // Set a maximum height
            }}
          />

          {isLessThan350 ? (
            <>
              <CustomDialog
                open={dialogOpen}
                onClose={handleClose}
                top="20px"
                right="20px"
              >
                <DialogContent>{leftSideContent}</DialogContent>
              </CustomDialog>
            </>
          ) : (
            <div style={{ width: "30%" }}>{leftSideContent}</div>
          )}

          <div style={{ flex: 1, padding: "0px" }}>
            <div style={{ padding: "20px" }}>
              <Box sx={{ width: "100%", marginBottom: "0px" }}>
                <Stepper activeStep={1} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>
            <div style={{ padding: "0px 0px 0px 0px" }}>
              {isLessThan350 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleModalOpen}
                  >
                    Show Templates
                  </Button>
                </div>
              )}
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isLessThan540 ? "column" : "row", // Stack buttons vertically on small screens
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40%", // Adjust as needed
                    backgroundColor: "#D9D9D9",
                    padding: "30px",
                    marginTop: "25px",
                    gap: "10px", // Small gap between buttons
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "10px", width: "100px" }}
                    onClick={handleBody}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                      }}
                    >
                      Body
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "10px", width: "100px" }}
                    onClick={handleButtons}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                      }}
                    >
                      Buttons
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "10px", width: "100px" }}
                    onClick={handleFooter}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                      }}
                    >
                      Footer
                    </Typography>
                  </Button>
                </Box>
                {open && (
                  <CustomDialog
                    open={open}
                    top="20px"
                    right="20px"
                    width="90%"
                    height="600px"
                    onClose={handleClose}
                    borderRadius="25px"
                  >
                    <DialogContent>
                      <Typography
                        fontSize="30px"
                        sx={{ fontFamily: "Roboto", color: "#0D75BA" }}
                      >
                        Body
                      </Typography>
                      <Typography
                        sx={{
                          color: "black",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                        }}
                      >
                        Enter Your Text
                      </Typography>
                      <Box sx={{ marginTop: "20px" }}>
                        <TextField
                          fullWidth
                          multiline
                          rows="4"
                          value={tempChange}
                          onChange={handleTextFieldChange}
                          InputProps={{
                            style: {
                              fontWeight: isBold ? "700" : "normal",

                              fontStyle: isItalic ? "italic" : "normal",
                            },
                          }}
                        />
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {/* Plus Icon with Dropdown */}
                          <div>
                            <img src={Smiley} style={{ marginTop: "10px" }} />
                          </div>
                          <div>
                            <img
                              src={Bold}
                              onClick={toggleBold}
                              style={{ marginTop: "10px" }}
                            />
                          </div>

                          <div>
                            <img
                              src={Italics}
                              onClick={toggleItalic}
                              style={{ marginTop: "10px" }}
                            />
                          </div>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              marginTop: "5px",
                            }}
                          >
                            <img
                              src={Plus}
                              alt="Plus"
                              onClick={handleIconClick}
                              style={{ cursor: "pointer" }}
                            />
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem
                                onClick={() =>
                                  handleMenuItemClick("Store name")
                                }
                              >
                                Store name
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleMenuItemClick("Store owner name")
                                }
                              >
                                Store owner name
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleMenuItemClick("Location")}
                              >
                                Location
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleMenuItemClick("Business type")
                                }
                              >
                                Business type
                              </MenuItem>
                            </Menu>
                            <span
                              style={{
                                color: "#0D75BA",
                                fontFamily: "Roboto",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Add variables
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          gap: "30px",
                          marginRight: smmatch ? "30px" : "80px",
                        }}
                      >
                        <CustomButton
                          onClick={handleClose}
                          width="170px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontFamily: "Inter",
                              fontWeight: "500",
                            }}
                          >
                            Cancel
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          onClick={handleAdd}
                          width="170px"
                          height="43px"
                          borderRadius="15px"
                          color="white"
                          background="#0D75BA"
                          variant="contained"
                        >
                          <Typography
                            sx={{
                              color: "white",
                              textTransform: "capitalize",
                              fontFamily: "Inter",
                              fontWeight: "500",
                            }}
                          >
                            Add
                          </Typography>
                        </CustomButton>
                      </Box>
                    </DialogActions>
                  </CustomDialog>
                )}
                {button && (
                  <CustomDialog
                    open={button}
                    top="20px"
                    right="20px"
                    width="90%"
                    height="600px"
                    onClose={handleClose}
                    borderRadius="25px"
                  >
                    <DialogContent>
                      <Typography
                        fontSize="30px"
                        sx={{
                          fontFamily: "Roboto",
                          color: "#0D75BA",
                          fontWeight: "500",
                        }}
                      >
                        Add Buttons
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {/* <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ color: "#0D75BA" }}
                                name="Interested"
                                checked={selectedLabel === "Interested"} // Check if this label is the selected one
                                onChange={handleCheckBox}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#0D75BA" }}>
                                Interested
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ color: "#0D75BA" }}
                                name="Not Interested"
                                checked={selectedLabel === "Not Interested"}
                                onChange={handleCheckBox}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#0D75BA" }}>
                                Not Interested
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ color: "#0D75BA" }}
                                name="View Website"
                                checked={selectedLabel === "View Website"}
                                onChange={handleCheckBox}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#0D75BA" }}>
                                View Website
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ color: "#0D75BA" }}
                                name="Call Now"
                                checked={selectedLabel === "Call Now"}
                                onChange={handleCheckBox}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#0D75BA" }}>
                                Call Now
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ color: "#0D75BA" }}
                                name="Copy Code"
                                checked={selectedLabel === "Copy Code"}
                                onChange={handleCheckBox}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#0D75BA" }}>
                                Copy Code
                              </Typography>
                            }
                          />
                        </FormGroup> */}
                        <RadioGroup onChange={handleRadioChange} sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}}>
        <FormControlLabel    value="pair1" control={<Radio sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}} />} label="Interested / Not Interested" />
        <FormControlLabel value="pair2" control={<Radio sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}} />} label="View Website / Call Now" />
        <FormControlLabel value="pair3" control={<Radio sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}} />} label="Get Started / Learn More" />
        <FormControlLabel value="pair4" control={<Radio sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}} />} label="Subscribe / No Thanks" />
        <FormControlLabel value="pair5" control={<Radio sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}} />} label="Shop Now / View Details" />
        <FormControlLabel value="pair6" control={<Radio sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}} />} label="Claim Offer / Skip Offer" />
        <FormControlLabel value="pair7" control={<Radio sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}} />} label="Schedule a Call / Send a Message" />
        <FormControlLabel value="pair8" control={<Radio sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}} />} label="Download App / Use Web Version" />
        <FormControlLabel value="pair9" control={<Radio sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}} />} label="Explore Products / Request Info" />
        <FormControlLabel value="pair10" control={<Radio sx={{
    color: "#0D75BA",
    '&.Mui-checked': {
      color: "#0D75BA",
    },}} />} label="Apply Now / Request More Info" />
      </RadioGroup>

                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: isLessThan540 ? "column" : "row",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          gap: "30px",
                          marginRight: smmatch ? "30px" : "80px",
                        }}
                      >
                        <CustomButton
                          onClick={handleClose}
                          width="170px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontFamily: "Inter",
                              fontWeight: "500",
                            }}
                          >
                            Cancel
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          onClick={handleApply}
                          width="170px"
                          height="43px"
                          borderRadius="15px"
                          color="white"
                          background="#0D75BA"
                          variant="contained"
                        >
                          <Typography
                            sx={{
                              color: "white",
                              textTransform: "capitalize",
                              fontFamily: "Inter",
                              fontWeight: "500",
                            }}
                          >
                            Apply
                          </Typography>
                        </CustomButton>
                      </Box>
                    </DialogActions>
                  </CustomDialog>
                )}
                {footer && (
                  <CustomDialog
                    open={footer}
                    top="20px"
                    right="20px"
                    width="90%"
                    height="600px"
                    onClose={handleClose}
                    borderRadius="25px"
                  >
                    <DialogContent>
                      <Typography
                        fontSize={contentFontsize_30}
                        sx={{
                          fontFamily: "Roboto",
                          color: "#0D75BA",
                          fontWeight: "500",
                        }}
                      >
                        Footer
                      </Typography>
                      {/* <br/> */}
                      <Typography
                        sx={{
                          color: "black",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                        }}
                      >
                        Enter Footer Text
                      </Typography>
                      <div style={{ marginTop: "20px" }}>
                        <TextField
                          fullWidth
                          multiline={true}
                          rows="1"
                          value={footerText}
                        ></TextField>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: isLessThan540 ? "column" : "row",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          gap: "30px",
                          marginRight: smmatch ? "30px" : "80px",
                        }}
                      >
                        <CustomButton
                          onClick={handleClose}
                          width="170px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontFamily: "Inter",
                              fontWeight: "500",
                            }}
                          >
                            Cancel
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          onClick={handleFooterOne}
                          width="170px"
                          height="43px"
                          // variant={active === "change" ? "contained" : "outlined"}
                          // background={active === "change" ? "#659B1B" : "transparent"}
                          // color={active === "change" ? "white" : "#659B1B"}
                          borderRadius="15px"
                          // borderColor="#659B1B"

                          color="white"
                          background="#0D75BA"
                          variant="contained"
                        >
                          <Typography
                            sx={{
                              color: "white",
                              textTransform: "capitalize",
                              fontFamily: "Inter",
                              fontWeight: "500",
                            }}
                          >
                            Add
                          </Typography>
                        </CustomButton>
                      </div>
                    </DialogActions>
                  </CustomDialog>
                )}
              </>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              {console.log("celesta", mediaSrc)}
              {value?.messageText ? (
                ""
              ) : (
                <img
                  src={mediaSrc} // Fallback to a default image if "media" is not found
                  style={{ width: "80px", height: "80px" }}
                  alt="Media"
                />
              )}
            </div>

            {(add || (previousSave && previousSave[0].length !== 0) || (previousNext && previousNext[0].length !== 0)) && (
              <>
                <div style={{ marginTop: "10px", width: "100%" }}>
                  {/* <Divider sx={{color:"black"}}/> */}
                  <hr />
                </div> 
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: smMatch
                      ? "30px 10px 30px 10px"
                      : "30px 30px 30px 30px",
                  }}
                >
                  <div style={{ textAlign: "left", width: "200px" }}>
                    <Typography
                      sx={{
                        fontWeight: isBold ? "700" : "normal",

                        fontStyle: isItalic ? "italic" : "normal",
                      }}
                    >
                      {previousSave ? previousSave[0]?.body : previousNext ? previousNext[0]?.body : change}  
                    </Typography>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Edit}
                        onClick={handleEdit}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          cursor: "pointer",
                          marginLeft: smMatch ? "10px" : "40px",
                        }}
                        onClick={handleDelete}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <hr />
                </div>
              </>
            )}
            {apply  && (
              <>
                <div
                  style={{ 
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: smMatch
                      ? "30px 10px 30px 10px"
                      : "30px 30px 30px 10px",
                  }}
                >
                  <div style={{ textAlign: "left", width: "200px" }}>
                    {/* <CustomButton
                      width="170px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        style={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                        }}
                      >
                        {selectedLabel}
                      </Typography>
                    </CustomButton> */}
                    {(selectedPair === 'pair1' ) && (
          <div style={{display:"flex",gap:"15px"}}>
          <CustomButton 
            width="250px"
            height="43px"
            borderRadius="15px"
            color="#0D75BA"
            background="white"
            variant="outlined"
            border="5px solid #0D75BA"
            borderColor="#0D75BA">
              <Typography
                 sx={{
                  color: "#0D75BA",
                  textTransform: "capitalize",
                  fontSize:"14px",
                  fontWeight:"400"
                }}
              >Interested</Typography>
          </CustomButton>
          <CustomButton 
            width="400px"
            height="43px"
            borderRadius="15px"
            color="#0D75BA"
            background="white"
            variant="outlined"
            border="5px solid #0D75BA"
            borderColor="#0D75BA">
              <Typography
                sx={{
                  color: "#0D75BA",
                  textTransform: "capitalize",
                  fontSize:"14px",
                  fontWeight:"400"
                }}
              >Not Interested</Typography>
          </CustomButton>
        </div>
        
        )}
        {selectedPair === 'pair2' && (
          <div style={{display:"flex",gap:"15px"}}>
            <CustomButton width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >View Website</Typography></CustomButton>
            <CustomButton width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                         <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >Call Now</Typography></CustomButton>
          </div>
        )}
        {selectedPair === 'pair3' && (
          <div  style={{display:"flex",gap:"15px"}}>
            <CustomButton width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >Get Started</Typography></CustomButton>
            <CustomButton  width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >Learn More</Typography></CustomButton>
          </div>
        )}
        {selectedPair === 'pair4' && (
          <div  style={{display:"flex",gap:"15px"}}>
            <CustomButton  width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >Subscribe</Typography></CustomButton>
            <CustomButton  width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >No Thanks</Typography></CustomButton>
          </div>
        )}
        {selectedPair === 'pair5' && (
          <div  style={{display:"flex",gap:"15px"}}>
            <CustomButton  width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >Shop Now</Typography></CustomButton>
            <CustomButton  width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >View Details</Typography></CustomButton>
          </div>
        )}
        {selectedPair === 'pair6' && (
          <div  style={{display:"flex",gap:"15px"}}>
            <CustomButton  width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >Claim Offer</Typography></CustomButton>
            <CustomButton  width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >Skip Offer</Typography></CustomButton>
          </div>
        )}
        {selectedPair === 'pair7' && (
          <div  style={{display:"flex",gap:"15px"}}>
            <CustomButton  width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >Schedule a Call</Typography></CustomButton>
            <CustomButton  width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}
                      >Send a Message</Typography></CustomButton>
          </div>
        )}
        {selectedPair === 'pair8' && (
          <div  style={{display:"flex",gap:"15px"}}>
            <CustomButton  width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography
                         sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize:"14px",
                          fontWeight:"400"
                        }}>Download App</Typography></CustomButton>
            <CustomButton  width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography  sx={{
                  color: "#0D75BA",
                  textTransform: "capitalize",
                  fontSize:"14px",
                  fontWeight:"400"
                }}>
                     Use Web Version     </Typography></CustomButton>
          </div>
        )}
        {selectedPair === 'pair9' && (
          <div  style={{display:"flex",gap:"15px"}}>
            <CustomButton  width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography  sx={{
                  color: "#0D75BA",
                  textTransform: "capitalize",
                  fontSize:"14px",
                  fontWeight:"400"
                }}>
                       Explore Products   </Typography></CustomButton>
            <CustomButton  width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography  sx={{
                  color: "#0D75BA",
                  textTransform: "capitalize",
                  fontSize:"14px",
                  fontWeight:"400"
                }}>
                    Request Info      </Typography></CustomButton>
          </div>
        )}
        {selectedPair === 'pair10' && (
          <div  style={{display:"flex",gap:"15px"}}>
            <CustomButton  width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography  sx={{
                  color: "#0D75BA",
                  textTransform: "capitalize",
                  fontSize:"14px",
                  fontWeight:"400"
                }}>
                     Apply Now      </Typography></CustomButton>
            <CustomButton  width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA">
                        <Typography  sx={{
                  color: "#0D75BA",
                  textTransform: "capitalize",
                  fontSize:"14px",
                  fontWeight:"400"
                }}>Request More Info</Typography></CustomButton>
          </div>
        )}
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Edit}
                        onClick={handleEditButton}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          cursor: "pointer",
                          marginLeft: smMatch ? "10px" : "40px",
                        }}
                        onClick={handleDelete1}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "20px", color: "black" }}>
                  <hr />
                </div>
              </>
            )}
            {one && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "20px 20px 20px 20px",
                  }}   
                >
                  <div style={{ textAlign: "left", width: "200px" }}>
                    <Typography
                      sx={{ fontFamily: "Roboto" }}
                      fontSize={contentFontsize_14}
                    >
                      {previousSave ? previousSave[0]?.footer : footerText}
                    </Typography>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Edit}
                        onClick={handleEditFooter}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          cursor: "pointer",
                          marginLeft: smMatch ? "10px" : "40px",
                        }}
                        onClick={handleDelete2}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "20px", color: "black" }}>
                  <hr />
                </div>
              </>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "200px",
                padding: "0px 10px 0px 0px",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <div>
                  <CustomButton
                    background="#0D75BA"
                    borderRadius="10px"
                    width="90px"
                    onClick={handlePrevious}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Previous
                    </Typography>
                  </CustomButton>
                </div>
                <div>
                  <CustomButton
                    background="#0D75BA"
                    borderRadius="10px"
                    width="90px"
                    onClick={handleNext}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Next
                    </Typography>
                  </CustomButton>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "200px",
                padding: "0px 10px 0px 0px",
              }}
            >
              <Typography>Step 2 of 3</Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
