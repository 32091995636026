import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetStoreList, DeleteStore_mutation,GetStoreSearchList} from '../../graphql/actions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CustomButton from '../Commoncomponents/button/index'
import { CircularProgress, Popper, Typography } from '@mui/material';
import { Popover } from '../MUIcomponents/index';
import { contentFontSize_16, contentFontSize_16_xs_10, contentFontSize_18_xs_13 } from '../Typography';
import threedot from '../../Utils/Images/threedot.png'
import { Dialog, DialogContent, useTheme, useMediaQuery } from '../MUIcomponents/index'
import Chat from './Chat';
import { message } from 'antd';
import { setClearAddStore, setRowDetails } from '../../redux/slices/marketingSlice'
import UpdateStore from './UpdateStore';
import {setSearchStoreClear,setCheckBoxDetails} from '../../redux/slices/marketingSlice'
import GroupChat from './GroupChat';

const HeaderStyle = {
  fontFamily: "Inter",
  fontWeight: "500",
  fontSize: "14px"
}

const cellStyle1 = {
  borderTop: '1px solid #E1CFFF',
  borderBottom: '1px solid #E1CFFF',
};

const cellStyle2 = {
  borderTop: '1px solid #E1CFFF',
  borderBottom: '1px solid #E1CFFF',
  fontFamily: "Inter",
  fontWeight: "500",
  fontSize: "14px"
};

function createData(id, checkbox, Storename, BussinessType, Address, email, mobile, status, edit) {
  return { id, checkbox, Storename, BussinessType, Address, email, mobile, status, edit };
}

// const rows = [
//   createData(1, true, 'Al saj', 'Restaurant', 'Kazhakuttom,Tri...', 'alsaj@gmailcom', '+91 222 222 2222', 'Invite Sent '),
//   createData(2, false, 'Al saj', 'Restaurant', 'Kazhakuttom,Tri...', 'alsaj@gmailcom', '+91 222 222 2222', 'Orientation'),
//   createData(3, true, 'Al saj', 'Restaurant', 'Kazhakuttom,Tri...', 'alsaj@gmailcom', '+91 222 222 2222', 'Waiting'),
//   createData(4, false, 'Al saj', 'Restaurant', 'Kazhakuttom,Tri...', 'alsaj@gmailcom', '+91 222 222 2222', 'Accepted'),
//   createData(5, true, 'Al saj', 'Restaurant', 'Kazhakuttom,Tri...', 'alsaj@gmailcom', '+91 222 222 2222', 'Rejected'),
// ];

export default function BasicTable({checkedState,setCheckedState,setChecked,onRowClick,open13,setOpen1}) {
 
  const [currentid, setCurrentid] = React.useState(null)
  const [currentStoreName,setCurrentStoreName]=React.useState('')
  const [currentBussinessType,setBussinessType]=React.useState('')
  const [currentState,setCurrentState]=React.useState('') 
  const [currentDistrict,setCurrentDistrict]=React.useState('')
  const [currentCity,setCurrentCity]=React.useState('')
  const [currentMobileNo,setCurrentMobileNo]=React.useState('')
  const [currentWhatsappNo,setCurrentWhatsAppNo]=React.useState('')
  const [currentAltMobileNo,setCurrentAltMobileNo]=React.useState('')
  const [currentEmailId,setCurrentEmailId]=React.useState('')
  const [currentStatus,setCurrentStatus]=React.useState('')
 




  const [openDialog, setOpenDialog] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openUpdateDialog, setUpdateDialog] = React.useState(false);

  const { deleteStoreDataSuccess } = useSelector((state) => state.marketingSlice);

  const handleUpdateStoreOpen = () => {
    setUpdateDialog(true)
  }

  const handleUpdateStoreClose = () => {
    setUpdateDialog(false)
    setAnchorEl(null)
  }
  console.log("store success",deleteStoreDataSuccess)
  useEffect(() => {
    if (deleteStoreDataSuccess) {
      setOpenDialog(false)
      message.success({
        content: "Store Deleted Successfully",
        className: "custom-success-message",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 1.5,
      });
      setTimeout(() => {
        // setOpenDialog(false)
        dispatch(setClearAddStore())
        dispatch(setSearchStoreClear())
        dispatch(GetStoreList());

      }, 1500)

    }

  }, [deleteStoreDataSuccess])

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"))
  const upLgmatch = useMediaQuery(theme.breakpoints.up("xl"));

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setAnchorEl(null);
  }

  console.log("this is current id", currentid)




  const dispatch = useDispatch();
  const { data, loading, error, addStoreDataSuccess,searchStoreData,filterStoreData,searchStoreLoading,filterStoreLoading} = useSelector((state) => state.marketingSlice);
 
  useEffect(() => {
    dispatch(GetStoreList());
  

  }, []);
  
  console.log("handle search",loading,searchStoreLoading,filterStoreLoading?.payload)
  useEffect(() => {
    if (data && Array.isArray(data?.listStores)) {
      const initialCheckedState = data?.listStores?.reduce((acc, store) => {
        acc[store.id] = false;
        return acc;
      }, {});
      setCheckedState(initialCheckedState);
      console.log("Initial checkedState:", initialCheckedState);
    } else {
      console.log("searchStoreData or searchStoreData.searchStores is not an array:", data);
    }
  }, [data]);
  const [checkedList, setCheckedList] = useState({});
console.log("list", Object.keys(checkedList).length);

  const handleChangeCheckout = (id) => {
    setCheckedState((prevState) => {
      const updatedCheckedState = { ...prevState, [id]: !prevState[id] };
      console.log("Updated checkedState:", updatedCheckedState);
      setCheckedList(updatedCheckedState)
      const allChecked = Object.values(updatedCheckedState).every(value => value);
      setChecked(allChecked);

      dispatch(setCheckBoxDetails(updatedCheckedState));
      return updatedCheckedState;
     
    });
  };


  // useEffect(() => {
  //   console.log("im excuted",addStoreDataSuccess)
  //   setTimeout(() => {
  //     dispatch(GetStoreList());
  //   }, 3000);




  // }, [addStoreDataSuccess]);

  console.log("this is the reduxData", filterStoreData)


  const handleClick = (event, id,storeName,businessType,state,district,city,mobileNo,whatsappNo,altMobileNo,emailId,status) => {
    setAnchorEl(event.currentTarget);
    setCurrentid(id)
    setCurrentStoreName(storeName)
    setBussinessType(businessType)
    setCurrentState(state)
    setCurrentDistrict(district)
    setCurrentCity(city)
    setCurrentMobileNo(mobileNo)
    setCurrentWhatsAppNo(whatsappNo)
    setCurrentAltMobileNo(altMobileNo)
    setCurrentEmailId(emailId)
    setCurrentStatus(status)

  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);




 

  const handleDelete = () => {
    dispatch(DeleteStore_mutation(currentid))
    setAnchorEl(null)
    setOpenDialog(false)

  }
  // useEffect(()=>{
  //     if(data=== null){
  //       setTimeout(()=>{
  //             <CircularProgress/>
  //       },3000)
  //     }
  // },[])
  console.log("length", data?.listStores)
  const {rowDetails} = useSelector((state) => state.marketingSlice);
  const[one,setOne] = useState(false)
  //console.log("open12",open12)
 const [clickedRows, setClickedRows] = useState({});

 const handleRowClick = (id) => {
  // Create a new state where the clicked row is true, and others are false
  const updatedState = data?.listStores.reduce((acc, row) => {
    acc[row.id] = row.id === id; // Set the clicked row's id to true, others to false
    return acc;
  }, {});

  // Dispatch the updated state if needed
  dispatch(setRowDetails(updatedState))
  
  // Call the parent function
  if (onRowClick) {
    onRowClick(id); // Pass the clicked row's id or any other relevant information
  }

  return updatedState;
};



  // Call the parent function
 

 
console.log("rows",rowDetails?.payload)
  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: "10px", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", overflowX: "auto", marginTop: "20px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ height: "41px" }}>
            <TableRow style={{ background: "#C6C6C6" }}>
              <TableCell></TableCell>
              <TableCell align="left" sx={HeaderStyle}>Store Name</TableCell>
              <TableCell align="left" sx={HeaderStyle}>Business Type</TableCell>
              <TableCell align="left" sx={HeaderStyle}>Location</TableCell>
              <TableCell align="left" sx={HeaderStyle}>Email</TableCell>
              <TableCell align="left" sx={HeaderStyle}>Mobile</TableCell>
              <TableCell align="left" sx={HeaderStyle}>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
{/* 
{ searchStoreData?(<>
  {searchStoreData?.searchStores?.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 },    background: checkedState[row.id] ? "#E1CFFF" : "inherit" }}
              >
                <TableCell component="th" scope="row" sx={cellStyle1}>
                  {console.log("checkedState", checkedState[row.id])}
                  <Checkbox
                    style={{ color: "#000000" }}
                    checked={checkedState[row.id]}
               onChange={() => handleChangeCheckout(row.id)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
                <TableCell align="left" sx={cellStyle2}  >{row?.storeName}</TableCell>
                <TableCell align="left" sx={cellStyle2}>{row?.businessType}</TableCell>
                <TableCell align="left" sx={cellStyle2}>{row?.district}</TableCell>
                <TableCell align="left" sx={cellStyle2}>{row?.emailId}</TableCell>
                <TableCell align="left" sx={cellStyle2}>{row?.mobileNo}</TableCell>
                <TableCell align="left" sx={cellStyle1}>
                  <CustomButton
                    background={
                      row.status === "Invite Sent" || row.status === "Accepted"
                        ? "#CCFFBE"
                        : row.status === "Waiting"
                          ? "#FFD392"
                          : "#FFA097"
                    }
                    color="#554B4B"
                    borderRadius="5px"
                    matchPadding={true}
                    padding="3px 10px"
                  >
                    <Typography style={{ textTransform: "capitalize", fontFamily: "Inter", fontWeight: "500" }} fontSize={contentFontSize_16}>
                      {row.status}
                    </Typography>

                  </CustomButton>

                </TableCell>
                <TableCell align="left" sx={cellStyle1}><img src={threedot} alt="three dot" onClick={(event) => handleClick(event, row.id,row.storeName,row.businessType,row.state,row.district,row.city,row.mobileNo,row.whatsappNo,row.altMobileNo,row.emailId,row.status)} style={{ cursor: "pointer" }} /></TableCell>
              </TableRow>
            ))}</>
):
(<>
            {data?.listStores?.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 },    background: checkedState[row.id] ? "#E1CFFF" : "inherit" }}
              >
                <TableCell component="th" scope="row" sx={cellStyle1}>
                  {console.log("checkedState for new", checkedState[row.id])}
                  <Checkbox
                    style={{ color: "#000000" }}
                    checked={checkedState[row.id] || false}
                    onChange={() => handleChangeCheckout(row.id)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
                <TableCell align="left" sx={cellStyle2}  >{row?.storeName}</TableCell>
                <TableCell align="left" sx={cellStyle2}>{row?.businessType}</TableCell>
                <TableCell align="left" sx={cellStyle2}>{row?.district}</TableCell>
                <TableCell align="left" sx={cellStyle2}>{row?.emailId}</TableCell>
                <TableCell align="left" sx={cellStyle2}>{row?.mobileNo}</TableCell>
                <TableCell align="left" sx={cellStyle1}>
                  <CustomButton
                    background={
                      row.status === "Invite Sent" || row.status === "Accepted"
                        ? "#CCFFBE"
                        : row.status === "Waiting"
                          ? "#FFD392"
                          : "#FFA097"
                    }
                    color="#554B4B"
                    borderRadius="5px"
                    matchPadding={true}
                    padding="3px 10px"
                  >
                    <Typography style={{ textTransform: "capitalize", fontFamily: "Inter", fontWeight: "500" }} fontSize={contentFontSize_16}>
                      {row.status}
                    </Typography>

                  </CustomButton>

                </TableCell>
                <TableCell align="left" sx={cellStyle1}><img src={threedot} alt="three dot" onClick={(event) => handleClick(event, row.id,row.storeName,row.businessType,row.state,row.district,row.city,row.mobileNo,row.whatsappNo,row.altMobileNo,row.emailId,row.status)} style={{ cursor: "pointer" }} /></TableCell>
              </TableRow>
            ))}</>
)
} */}


{filterStoreData?.payload?.filterStores?.length > 0 ? (
  <>
    {filterStoreData.payload.filterStores.map((row, index) => (
      <TableRow
        key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: checkedState[row.id] ? "#E1CFFF" : "inherit" }}
        
      >
        <TableCell component="th" scope="row" sx={cellStyle1} >
          <Checkbox
            style={{ color: "#000000" }}
            checked={checkedState[row.id] || false}
            onChange={() => handleChangeCheckout(row.id)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
        <TableCell align="left" sx={cellStyle2} onClick={()=> handleRowClick(row.id)}>{row?.storeName}</TableCell>
        <TableCell align="left" sx={cellStyle2} onClick={()=> handleRowClick(row.id)}> {row?.businessType?.join(", ")}</TableCell>
        <TableCell align="left" sx={cellStyle2} onClick={()=> handleRowClick(row.id)}>{row?.city}</TableCell>
        <TableCell align="left" sx={cellStyle2} onClick={()=> handleRowClick(row.id)}>{row?.emailId}</TableCell>
        <TableCell align="left" sx={cellStyle2} onClick={()=> handleRowClick(row.id)}>{row?.mobileNo}</TableCell>
        <TableCell align="left" sx={cellStyle1}>
          <CustomButton
            background={
              row.status === "Invite Sent" || row.status === "Accepted"
                ? "#CCFFBE"
                : row.status === "Waiting"
                  ? "#FFD392"
                  : row.status=== null ? "#0D75BA": "#FFA097"
            }
            color="#554B4B"
            borderRadius="5px"
            matchPadding={true}
            padding="3px 10px"
            onClick={()=> handleRowClick(row.id)}
          >
            <Typography style={{ textTransform: "capitalize", fontFamily: "Inter", fontWeight: "500",color:"white" }} fontSize={contentFontSize_16}>
            {row.status === null ? "Send Invite" :" null"}
            </Typography>
          </CustomButton>
        </TableCell>
        <TableCell align="left" sx={cellStyle1}>
          <img src={threedot} alt="three dot" onClick={(event) => handleClick(event, row.id, row.storeName, row.businessType, row.state, row.district, row.city, row.mobileNo, row.whatsappNo, row.altMobileNo, row.emailId, row.status)} style={{ cursor: "pointer" }} />
        </TableCell>
      </TableRow>
    ))}
  </>
) : searchStoreData?.searchStores?.length > 0 ? (
  
  <>
    {searchStoreData.searchStores.map((row, index) => (
      <TableRow
        key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: checkedState[row.id] ? "#E1CFFF" : "inherit" }}
      
      >
        <TableCell component="th" scope="row" sx={cellStyle1}>
          <Checkbox
            style={{ color: "#000000" }}
            checked={checkedState[row.id] || false}
            onChange={() => handleChangeCheckout(row.id)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
        <TableCell align="left" sx={cellStyle2} onClick={()=> handleRowClick(row.id)}>{row?.storeName}</TableCell>
        <TableCell align="left" sx={cellStyle2} onClick={()=> handleRowClick(row.id)}>{row?.businessType}</TableCell>
        <TableCell align="left" sx={cellStyle2} onClick={()=> handleRowClick(row.id)}>{row?.city}</TableCell>
        <TableCell align="left" sx={cellStyle2} onClick={()=> handleRowClick(row.id)}>{row?.emailId}</TableCell>
        <TableCell align="left" sx={cellStyle2} onClick={()=> handleRowClick(row.id)}>{row?.mobileNo}</TableCell>
        <TableCell align="left" sx={cellStyle1}>
          <CustomButton
            background={
              row.status === "Invite Sent" || row.status === "Accepted"
                ? "#CCFFBE"
                : row.status === "Waiting"
                  ? "#FFD392"
                  : row.status=== null ? "#0D75BA": "#FFA097"
            }
            color="#554B4B"
            borderRadius="5px"
            matchPadding={true}
            padding="3px 10px"
            onClick={()=> handleRowClick(row.id)}
          >
            <Typography style={{ textTransform: "capitalize", fontFamily: "Inter", fontWeight: "500",color:"white" }} fontSize={contentFontSize_16}>
              {row.status === null ? "Send Invite" :" null"}
            </Typography>
          </CustomButton>
        </TableCell>
        <TableCell align="left" sx={cellStyle1}>
          <img src={threedot} alt="three dot" onClick={(event) => handleClick(event, row.id, row.storeName, row.businessType, row.state, row.district, row.city, row.mobileNo, row.whatsappNo, row.altMobileNo, row.emailId, row.status)} style={{ cursor: "pointer" }} />
        </TableCell>
      </TableRow>
    ))}
  </>
) : (
  data?.listStores?.length>0?
  <>
    {data?.listStores?.map((row, index) => (
      
      <TableRow
        key={row?.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: checkedState[row?.id] ? "#E1CFFF" : "inherit" }}
       
      >
        <TableCell component="th" scope="row" sx={cellStyle1}>
          <Checkbox
            style={{ color: "#000000" }}
            checked={checkedState[row?.id] || false}
            onChange={() => handleChangeCheckout(row?.id)}
            inputProps={{ 'aria-label': 'controlled' }}
            onClick={(e) => e.stopPropagation()}
          />
        </TableCell>
        <TableCell align="left" sx={cellStyle2}  onClick={()=> handleRowClick(row.id)}>{row?.storeName}</TableCell>
        <TableCell align="left" sx={cellStyle2}>
  {row?.businessType?.join(", ")}
</TableCell>

        <TableCell align="left" sx={cellStyle2}  onClick={()=> handleRowClick(row.id)}>{row?.city}</TableCell>
        <TableCell align="left" sx={cellStyle2}  onClick={()=> handleRowClick(row.id)}>{row?.emailId}</TableCell>
        <TableCell align="left" sx={cellStyle2}  onClick={()=> handleRowClick(row.id)}> +91{row?.mobileNo}</TableCell>
        <TableCell align="left" sx={cellStyle1}>
          <CustomButton
            background={
              row.status === "Invite Sent" || row.status === "Accepted"
                ? "#CCFFBE"
                : row.status === "Waiting"
                  ? "#FFD392"
                  : row.status=== null ? "#0D75BA": "#FFA097"
            }
            color="#554B4B"
            borderRadius="5px"
            matchPadding={true}
            padding="3px 10px"
            onClick={()=> handleRowClick(row.id)}
          >
            <Typography style={{ textTransform: "capitalize", fontFamily: "Inter", fontWeight: "500",color:"white" }} fontSize={contentFontSize_16}>
            {row.status === null ? "Send Invite" :" null"}
            </Typography>
          </CustomButton>
        </TableCell>
        <TableCell align="left" sx={cellStyle1}  >
          <img src={threedot} alt="three dot" onClick={(event) => handleClick(event, row.id, row.storeName, row.businessType, row.state, row.district, row.city, row.mobileNo, row.whatsappNo, row.altMobileNo, row.emailId, row.status)} style={{ cursor: "pointer" }} />
        </TableCell>
      </TableRow>
    ))}
  </>:
  (loading || searchStoreLoading || filterStoreLoading?.payload) 
  ? <>Loading....</> 
  : <>No Data found</>

)}



          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { borderRadius: 3 }, // Adjust the borderRadius value as needed
        }}

      >
        <div style={{ background: "white", width: "100px", borderRadius: "20px" }}>
          <Typography className='chatFontHeebo400' fontSize={contentFontSize_18_xs_13} style={{ cursor: "pointer", transition: "color 0.3s,background-color 0.3s", padding: "10px 10px 5px 10px" }}
            onMouseEnter={(e) => {
              e.target.style.color = "#EA4335";
              e.target.style.backgroundColor = "#C4C4C440"; // Change to the desired color
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "initial";
              e.target.style.backgroundColor = "initial";
            }}
            onClick={handleUpdateStoreOpen}
          >Edit</Typography>
          <Typography className='chatFontHeebo400' fontSize={contentFontSize_18_xs_13} style={{ cursor: "pointer", transition: "color 0.3s,background-color 0.3s", padding: "5px 10px 10px 10px" }}
            onMouseEnter={(e) => {
              e.target.style.color = "#EA4335";
              e.target.style.backgroundColor = "#C4C4C440"; // Change to the desired color
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "initial";
              e.target.style.backgroundColor = "initial";
            }}
            onClick={handleOpenDialog}
          >Delete</Typography>
        </div>
      </Popover>

      {openDialog && (
        <Dialog
          open={openDialog}
        >
          <DialogContent>
            <div style={{ height: "100%", width: "100%", padding: matches ? "10px" : "15px" }}>
              <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                <Typography fontSize={contentFontSize_16_xs_10} className='chatFontHeebo400Color'>Do you want to delete this Shop?</Typography>

                <div style={{ display: "flex", gap: matches ? "10px" : "15px", textAlign: "center", justifyContent: "space-evenly" }}>
                  <Typography fontSize={contentFontSize_16_xs_10} onClick={handleCloseDialog} className='chatFontHeebo400Color' style={{ cursor: "pointer" }}>Cancel</Typography>
                  <Typography fontSize={contentFontSize_16_xs_10}>|</Typography>
                  <Typography fontSize={contentFontSize_16_xs_10} onClick={handleDelete} className='chatFontHeebo400Red' style={{ cursor: "pointer" }}>Delete</Typography>
                </div>

              </div>

            </div>
          </DialogContent>
        </Dialog>
      )}

      {openUpdateDialog && (<UpdateStore  setAnchorEl={ setAnchorEl} openUpdateDialog={openUpdateDialog} setUpdateDialog={setUpdateDialog} handleUpdateStoreClose={handleUpdateStoreClose} currentid={currentid} currentStoreName={currentStoreName} currentBussinessType={currentBussinessType} currentState={currentState} currentDistrict={currentDistrict} currentCity={currentCity} currentMobileNo={currentMobileNo} currentWhatsappNo={currentWhatsappNo} currentAltMobileNo={currentAltMobileNo} currentEmailId={currentEmailId} currentStatus={currentStatus} />)}

      {/* {one && (
        <GroupChat one={one}/>
      )} */}
    </>
  );
}


