// import React, { useEffect } from 'react';
// import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import ResponsiveDrawer from './components/ResponsiveDrawer';
// import Login from './components/Login/Login';
// import SignUp from './components/SignUp/SignUp';
// import Dashboard from './components/Dashboard/Dashboard';
// import Stores from './components/Stores/Stores';
// import Template from './components/Templates/Template';
// import Report from './components/Reports/Report';
// import TemplateElements from './components/Templates/TemplateElements';
// import TemplateForm from './components/Templates/TemplateForm';
// import TemplateSave from './components/Templates/TemplateSave';
// import ProtectedRoute from './ProtectedRoute';
// import { useDispatch } from 'react-redux';
// import { getToken } from './graphql/actions';




// function App() {
  
//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login />} />
//         <Route path="/signup" element={<SignUp />} />
//         <Route path="*" element={<MainApp />} />
//       </Routes>
//     </Router>
//   );
// }

// function MainApp() {
//   const authToken = localStorage.getItem('authToken');

//   return (
//     <ResponsiveDrawer>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             authToken ? (
//               <ProtectedRoute>
//                 <Dashboard />
//               </ProtectedRoute>
//             ) : (
//               <Navigate to="/login" />
//             )
//           }
//         />
//         <Route
//           path="/store"
//           element={
//             authToken ? (
//               <ProtectedRoute>
//                 <Stores />
//               </ProtectedRoute>
//             ) : (
//               <Navigate to="/login" />
//             )
//           }
//         />
//         <Route
//           path="/template"
//           element={
//             authToken ? (
//               <ProtectedRoute>
//                 <Template />
//               </ProtectedRoute>
//             ) : (
//               <Navigate to="/login" />
//             )
//           }
//         />
//         <Route
//           path="/template/templateForm"
//           element={
//             authToken ? (
//               <ProtectedRoute>
//                 <TemplateForm />
//               </ProtectedRoute>
//             ) : (
//               <Navigate to="/login" />
//             )
//           }
//         />
//         <Route
//           path="/template/templateElements"
//           element={
//             authToken ? (
//               <ProtectedRoute>
//                 <TemplateElements />
//               </ProtectedRoute>
//             ) : (
//               <Navigate to="/login" />
//             )
//           }
//         />
//         <Route
//           path="/template/templateSave"
//           element={
//             authToken ? (
//               <ProtectedRoute>
//                 <TemplateSave />
//               </ProtectedRoute>
//             ) : (
//               <Navigate to="/login" />
//             )
//           }
//         />
//         <Route
//           path="/report"
//           element={
//             authToken ? (
//               <ProtectedRoute>
//                 <Report />
//               </ProtectedRoute>
//             ) : (
//               <Navigate to="/login" />
//             )
//           }
//         />
//         <Route path="*" element={<Navigate to="/" />} />
//       </Routes>
//     </ResponsiveDrawer>
//   );
// }

// export default App;

import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ResponsiveDrawer from './components/ResponsiveDrawer';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import Dashboard from './components/Dashboard/Dashboard';
import Stores from './components/Stores/Stores';
import Template from './components/Templates/Template';
import Report from './components/Reports/Report';
import TemplateElements from './components/Templates/TemplateElements';
import TemplateForm from './components/Templates/TemplateForm';
import TemplateSave from './components/Templates/TemplateSave';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        {/* Wrap main app routes in a layout component */}
        <Route path="*" element={<MainApp />} />
      </Routes>
    </Router>
  );
}

function MainApp() {
  return (
    <ResponsiveDrawer>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/store"
          element={
            <ProtectedRoute>
              <Stores />
            </ProtectedRoute>
          }
        />
        <Route
          path="/template"
          element={
            <ProtectedRoute>
              <Template />
            </ProtectedRoute>
          }
        />
        <Route
          path="/template/templateForm"
          element={
            <ProtectedRoute>
              <TemplateForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/template/templateElements"
          element={
            <ProtectedRoute>
              <TemplateElements />
            </ProtectedRoute>
          }
        />
        <Route
          path="/template/templateSave"
          element={
            <ProtectedRoute>
              <TemplateSave />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report"
          element={
            <ProtectedRoute>
              <Report />
            </ProtectedRoute>
          }
        />
        {/* Redirect to Dashboard or login if an invalid route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ResponsiveDrawer>
  );
}

export default App;
