// import { Dialog, DialogContent, Box, MenuItem, Typography, Select, FormControl, useMediaQuery, useTheme, TextField, InputAdornment, Drawer, Avatar, AvatarGroup, createTheme, ThemeProvider } from '../MUIcomponents/index';
// import { contentFontSize_20, contentFontsize_30, contentFontSize_16, contentFontSize_18, HeaderFontSize_26, contentFontsize_14_xs_10, HeaderNavigationFontSize_12, contentFontsize_14 } from '../Typography'
// import React, { useState } from 'react'
// import './Stores.css'
// import Rectangle from '../../Utils/Images/Rectangle.png'
// import closeIcon from '../../Utils/Images/closeIcon.png'
// import AS from '../../Utils/Images/AS.png'
// import avatar from '../../Utils/Images/avatar.png';
// import call from '../../Utils/Images/call.png'
// import mobile from '../../Utils/Images/mobile.png'
// import email from '../../Utils/Images/email.png'
// import whatsappImg from '../../Utils/Images/whatsappImg.png'
// import threedotHori from '../../Utils/Images/threedotHori.png'
// import send from '../../Utils/Images/send.png'
// import Upload from '../../Utils/Images/Upload.png'
// import smiley from '../../Utils/Images/smiley.png'
// import readreceipt from '../../Utils/Images/readreceipt.png'
// import { Input } from '@mui/material';
// import CustomButton from '../Commoncomponents/button';
// import man from '../../Utils/Images/man.jpg'

// export default function GroupChat({ open2, handleClose2 }) {
//     const [open1, setOpen1] = React.useState(false);
//     const [individual, setIndividual] = React.useState(false)

//     const toggleDrawer = (newOpen) => () => {
//         setOpen1(newOpen);
//     };
//     const theme = useTheme();
//     const matches = useMediaQuery(theme.breakpoints.down("md"));
//     const smmatch = useMediaQuery(theme.breakpoints.down("sm"))
//     const upsmmatch = useMediaQuery(theme.breakpoints.up("sm"));
//     const upLgmatch = useMediaQuery(theme.breakpoints.up("xl"));
//     const [select, setSelect] = useState('')

//     const [messages, setMessages] = useState([]);
//     const [input, setInput] = useState('');

//     const handleSend = () => {
//         if (input.trim() !== '') {
//             setMessages([...messages, { text: input, sent: true }]);
//             setInput('');
//         }
//     };

//     const handleKeyPress = (event) => {
//         if (event.key === 'Enter') {
//             handleSend();
//         }
//     };

//     const handleChange = (event) => {
//         setSelect(event.target.value)
//     }
//     const StatusFilter = [
//         { id: 1, label: "Accepted" },
//         { id: 2, label: "rejected" },
//         { id: 3, label: "completed" }
//     ]

//     const templates = [
//         { id: 1, label: "Introduction", image: Rectangle },
//         { id: 2, label: "Welcome", image: Rectangle },
//         { id: 3, label: "Introduction", image: Rectangle },
//         { id: 4, label: "Welcome", image: Rectangle },
//         { id: 5, label: "Introduction", image: Rectangle },
//         { id: 6, label: "Welcome", image: Rectangle },
//         { id: 7, label: "Introduction", image: Rectangle },
//         { id: 8, label: "Welcome", image: Rectangle },
//         { id: 9, label: "Introduction", image: Rectangle },
//         { id: 10, label: "Welcome", image: Rectangle },
//         { id: 11, label: "Introduction", image: Rectangle },
//         { id: 12, label: "Welcome", image: Rectangle },
//     ]

//     const AvatarList = [
//         { id: 1, image: man },
//         { id: 2, image: man },
//         { id: 3, image: man },
//         { id: 4, image: man },
//         { id: 5, image: man },
//         { id: 6, image: man },
//     ]
//     return (
//         <div>
//             <Dialog
//                 open={open2}
//                 onClose={handleClose2}
//                 aria-labelledby="alert-dialog-title"
//                 aria-describedby="alert-dialog-description"

//                 maxWidth={"1000"}>
//                 <DialogContent sx={{ padding: "0px", borderBottom: "1px solid #DADADA" }}>
//                     <div style={{ display: "flex", gap: "10px", flexDirection: "column", overflowX: smmatch && "hidden" }}>
//                         <div style={{ padding: "20px" }}>
//                             <div style={{ display: "flex", justifyContent: "space-between" }}>
//                                 {!individual ? <div style={{ marginLeft: "20px" }}>
//                                     <ThemeProvider
//                                         theme={createTheme({
//                                             components: {
//                                                 MuiAvatarGroup: {
//                                                     styleOverrides: {
//                                                         root: ({ ownerState: { max } }) => ({
//                                                             ...[...Array(max)].reduce(
//                                                                 (result, curr, index) => ({
//                                                                     ...result,
//                                                                     [`& > .MuiAvatar-root:nth-child(${index + 1})`]: {
//                                                                         zIndex: max - index,
//                                                                         marginLeft: -24 * 1,  // 60% of the avatar width (assuming 24px as the default size)
//                                                                         width: matches ? 35 : 46,
//                                                                         height: matches ? 35 : 46
//                                                                     }
//                                                                 }),
//                                                                 {}
//                                                             )
//                                                         })
//                                                     }
//                                                 }
//                                             }
//                                         })}
//                                     >
//                                         <AvatarGroup max={3}>


//                                             {AvatarList.map((avatar) => (
//                                                 <Avatar alt="Remy Sharp" src={avatar.image} sx={{ width: matches ? 40 : 46, height: matches ? 40 : 46 }} />
//                                             ))
//                                             }

//                                         </AvatarGroup>
//                                     </ThemeProvider>
//                                 </div> : <div style={{ display: "flex", gap: '5px', alignItems: "center" }}>
//                                     <div style={{ height: "10px", width: "10px", background: "#13801C", borderRadius: "50%" }}></div>
//                                     <Typography className='chatFont' fontSize={contentFontSize_16} >Accepted</Typography>
//                                 </div>}
//                                 <div><img src={closeIcon} alt='closeicon' width={matches ? "13px" : "20px"} height={matches ? "13px" : "20px"} /> </div>
//                             </div>


//                             {/* //new one */}
//                             {individual && (<> <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: matches && "wrap" }}>
//                                 <div style={{ display: "flex", gap: "20px" }}>
//                                     <div>
//                                         {/* <img src={AS} alt='storeimage' width="91px" height="91px" style={{ borderRadius: "50%" }} />  */}
//                                         <Avatar
//                                             alt="Remy Sharp"
//                                             src={AS}
//                                             sx={{ width: matches ? 70 : 91, height: matches ? 70 : 91 }}
//                                         />
//                                     </div>
//                                     <div style={{ width: "400px" }}>
//                                         <div style={{ display: "flex", flexDirection: "column", gap: matches ? '1px' : '5px' }}>
//                                             <Typography className='chatFontHeebo' fontSize={HeaderFontSize_26}>Store name</Typography>
//                                             <Typography className='chatFontHeebo400' fontSize={contentFontSize_20}>store business</Typography>
//                                             <Typography className='chatFontHeebo400Color' fontSize={contentFontsize_14_xs_10}>store address</Typography>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
//                                     <div style={{ display: 'flex', gap: smmatch ? "10px" : "20px", flexWrap: smmatch && "wrap", marginTop: matches && "10px" }}>
//                                         <div style={{ display: "flex", gap: "10px", flexWrap: smmatch && "wrap" }}>
//                                             <div className='centerAlign'><img src={call} alt="call image" /> </div>
//                                             <Typography className='chatFontHeebo400' fontWeight={contentFontSize_16}>+971 42 0000 00</Typography>
//                                         </div>
//                                         <div style={{ display: "flex", gap: "10px", flexWrap: smmatch && "wrap" }}>
//                                             <div className='centerAlign'> <img src={mobile} alt='mobile image' /> </div>
//                                             <Typography className='chatFontHeebo400' fontWeight={contentFontSize_16}>+91 00 0000 0000</Typography>
//                                         </div>
//                                     </div>
//                                     <div style={{ display: 'flex', gap: smmatch ? "10px" : "20px", flexWrap: smmatch && "wrap" }}>
//                                         <div style={{ display: "flex", gap: "10px", flexWrap: smmatch && "wrap" }}>
//                                             <div className='centerAlign'> <img src={whatsappImg} alt='whatsapp image' /> </div>
//                                             <Typography className='chatFontHeebo400' fontWeight={contentFontSize_16}>+91 00 0000 000</Typography>
//                                         </div>
//                                         <div style={{ display: "flex", gap: "10px", flexWrap: smmatch && "wrap" }}>
//                                             <div className='centerAlign'> <img src={email} alt="email image" /> </div>
//                                             <Typography className='chatFontHeebo400' fontWeight={contentFontSize_16}>alsaj@gmail.com</Typography>
//                                         </div>
//                                     </div>

//                                 </div>

//                             </div>
//                                 <div className='chatSelect'>
//                                     <Box sx={{ minWidth: smmatch ? 1 : 200 }}>
//                                         <FormControl fullWidth>

//                                             <Select
//                                                 sx={{
//                                                     borderRadius: "10px",
//                                                     background: "#0D75BA",
//                                                     color: "white",
//                                                     height: matches ? "36px" : "42px",
//                                                     width: "100%",
//                                                     '.MuiOutlinedInput-notchedOutline': {
//                                                         borderColor: '#0D75BA',

//                                                     },
//                                                     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                                                         borderColor: '#0D75BA',
//                                                     },
//                                                     '&:hover .MuiOutlinedInput-notchedOutline': {
//                                                         borderColor: '#0D75BA',
//                                                     },
//                                                 }}

//                                                 displayEmpty

//                                                 value={select}

//                                                 onChange={handleChange}
//                                                 renderValue={(selected) => {

//                                                     if (!selected) {
//                                                         return <Typography style={{ fontFamily: "Heebo", fontWeight: "400", color: "white" }} fontSize={contentFontSize_16}>Select</Typography>;
//                                                     }
//                                                     return <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>{selected}</Typography>;
//                                                 }}

//                                             >
//                                                 {StatusFilter.map((item) => (
//                                                     <MenuItem key={item.id} value={item.label}>
//                                                         <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>
//                                                             {item.label}
//                                                         </Typography>
//                                                     </MenuItem>
//                                                 ))}
//                                             </Select>
//                                         </FormControl>
//                                     </Box>
//                                 </div></>)}





//                             {smmatch && <div style={{ marginTop: "10px" }}>
//                                 <CustomButton background="#0D75BA" onClick={toggleDrawer(true)}><Typography style={{ fontFamily: "Heebo", fontWeight: "400", textTransform: "capitalize" }} fontSize={contentFontSize_16}>Templates</Typography></CustomButton>
//                             </div>
//                             }
//                             <Dialog open={open1} onClose={toggleDrawer(false)}>
//                                 <div style={{ margin: '0px 0px' }}>
//                                     <div style={{ textAlign: "center", padding: "20px" }}>
//                                         <Typography className='chatFont' fontSize={contentFontSize_20}>Templates</Typography>

//                                     </div>

//                                     <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap", width: matches ? "230px" : "300px", padding: "20px" }}>
//                                         {templates.map((template) => (
//                                             <div style={{ display: "flex", gap: "10px", flexBasis: "calc(50% - 10px)", boxSizing: "border-box" }} key={template.id}>
//                                                 <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
//                                                     <img src={template.image} alt={template.label} style={{ width: matches ? "90px" : "124px", height: matches ? "110px" : "146px" }} />
//                                                     <Typography className='chatFontCenter' fontSize={HeaderNavigationFontSize_12}>{template.label}</Typography>
//                                                 </div>
//                                             </div>
//                                         ))}
//                                     </div>

//                                 </div>
//                             </Dialog>
//                         </div>





//                         <div style={{ display: "flex", borderTop: "1px solid #DADADA" }}>
//                             {upsmmatch && (<div style={{ margin: '0px 0px' }}>
//                                 <div style={{ textAlign: "center", padding: "20px" }}>
//                                     <Typography className='chatFont' fontSize={contentFontSize_20}>Templates</Typography>

//                                 </div>

//                                 <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap", width: matches ? "230px" : "300px", padding: "20px", overflowY: "scroll", height: "400px", msOverflowStyle: "none", scrollbarWidth: "none" }}>
//                                     {templates.map((template) => (
//                                         <div style={{ display: "flex", gap: "10px", flexBasis: "calc(50% - 10px)", boxSizing: "border-box" }} key={template.id}>
//                                             <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
//                                                 <img src={template.image} alt={template.label} style={{ width: matches ? "90px" : "124px", height: matches ? "110px" : "146px" }} />
//                                                 <Typography className='chatFontCenter' fontSize={HeaderNavigationFontSize_12}>{template.label}</Typography>
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </div>

//                             </div>)}


//                             <div style={{ width: "656px", height: "516px", borderLeft: "1px solid #DADADA" }}>
//                                 <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
//                                     <div style={{ width: "100%", height: "68px", borderBottom: "1px solid #E5E5EA", padding: "0px 10px" }}>
//                                         <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%" }}>
//                                             {/* <img src={avatar} alt='profile image' width="24" height="24" style={{ borderRadius: "50%" }} /> */}
//                                             {individual ? <Avatar
//                                                 alt="Remy Sharp"
//                                                 src={avatar}
//                                                 sx={{ width: 24, height: 24 }}
//                                             /> : <div></div>}
//                                             <div style={{ display: "flex", gap: "3px", flexDirection: "column" }}>
//                                                 <Typography style={{ fontFamily: "Inter", fontWeight: "700", textAlign: "center" }} fontSize={contentFontSize_20}>
//                                                     {individual ? <>AL Saj</> : <>Messengers</>}
//                                                 </Typography>
//                                                 <Typography className='chatFontHeebo400Color' fontSize={HeaderNavigationFontSize_12} style={{ textAlign: "center" }}>
//                                                     {individual ? <>last seen 45 minutes ago</> : <>Active</>}

//                                                 </Typography>
//                                             </div>
//                                             <img src={threedotHori} alt='threedot' width="16" height="3" />

//                                         </div>
//                                     </div>
//                                     <div className="messages">
//                                         {messages.map((message, index) => (
//                                             <div key={index} className="message" style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
//                                                 <div style={{ width: "50%", height: "100%", background: "#007AFF", color: "white", borderRadius: "6px", padding: matches ? "10px 10px 15px 10px" : "10px 10px 25px 10px", position: "relative", wordWrap: "break-word" }}>
//                                                     <Typography style={{ width: "100%", height: "100%", fontFamily: "Inter", fontWeight: "400" }} fontSize={contentFontsize_14}>{message.text}</Typography>

//                                                 </div>

//                                                 <div style={{ display: "flex", gap: "5px", position: "absolute", bottom: "10px", alignItems: "center", right: "15px" }}>
//                                                     <Typography className='chatFontHeebo400White' fontSize={HeaderNavigationFontSize_12}>5:78 pm</Typography>
//                                                     <img src={readreceipt} alt='read receipt' width="16px" height="8px" />

//                                                 </div>
//                                             </div>
//                                         ))}
//                                     </div>
//                                     <div style={{ width: "100%", borderTop: "1px solid #E5E5EA" }}>
//                                         <TextField
//                                             variant="outlined"
//                                             placeholder='Start Typing....'
//                                             multiline

//                                             // rows='1'
//                                             value={input}
//                                             name="Search"
//                                             onChange={(e) => setInput(e.target.value)}

//                                             InputProps={{

//                                                 endAdornment: (
//                                                     <InputAdornment position="start" sx={{ width: '89px' }}>
//                                                         <div style={{ display: "flex", alignItems: "center", width: "100%", height: "100%", gap: '20px' }}>
//                                                             <div><img src={Upload} alt='upload image' style={{ cursor: "pointer" }} /> </div>
//                                                             <div onClick={handleSend}> <img src={send} alt='send icon' style={{ cursor: "pointer" }} /> </div>
//                                                         </div>


//                                                     </InputAdornment>
//                                                 ),
//                                                 startAdornment: (
//                                                     <InputAdornment position='start' sx={{ width: '30px' }}>
//                                                         <div style={{ display: "flex", alignItems: "center" }}>
//                                                             <img src={smiley} alt='smiley icon' style={{ cursor: "pointer" }} />
//                                                         </div>
//                                                     </InputAdornment>
//                                                 ),
//                                                 sx: {
//                                                     fontSize: matches && '13px',
//                                                     // height: matches ? "40px" : "48x",
//                                                     // background: "white",
//                                                 },
//                                             }}

//                                             sx={{
//                                                 width: '100%',

//                                                 '& .MuiOutlinedInput-root': {
//                                                     borderRadius: '16px',
//                                                     '& fieldset': {
//                                                         borderColor: 'white', // This will change the border color
//                                                         borderTop: "black"
//                                                     },
//                                                     '&:hover fieldset': {
//                                                         borderColor: 'white', // This will change the border color on hover
//                                                     },
//                                                     '&.Mui-focused fieldset': {
//                                                         borderColor: 'white', // This will change the border color when focused
//                                                     },
//                                                 },

//                                                 '& .MuiInputBase-input': {
//                                                     backgroundColor: 'white',
//                                                 },
//                                             }}


//                                         />



//                                     </div>
//                                 </div>

//                             </div>
//                         </div>

//                     </div>
//                 </DialogContent>
//             </Dialog>
//         </div>
//     )
// }




//new code 
import { Dialog, DialogContent, Box, MenuItem, Typography, Select, FormControl, useMediaQuery, useTheme, TextField, InputAdornment, Drawer, Avatar, Popover, ThemeProvider, createTheme, AvatarGroup } from '../MUIcomponents/index';
import { contentFontSize_20, contentFontsize_30, contentFontSize_16, contentFontSize_18, HeaderFontSize_26, contentFontsize_14_xs_10, HeaderNavigationFontSize_12, contentFontsize_14 } from '../Typography'
import React, { useEffect, useRef, useState } from 'react'
import './Stores.css'
import Rectangle from '../../Utils/Images/Rectangle.png'
import closeIcon from '../../Utils/Images/closeIcon.png'
import AS from '../../Utils/Images/AS.png'
import avatar from '../../Utils/Images/avatar.png';
import call from '../../Utils/Images/call.png'
import mobile from '../../Utils/Images/mobile.png'
import email from '../../Utils/Images/email.png'
import whatsappImg from '../../Utils/Images/whatsappImg.png'
import threedotHori from '../../Utils/Images/threedotHori.png'
import send from '../../Utils/Images/send.png'
import Upload from '../../Utils/Images/Upload.png'
import smiley from '../../Utils/Images/smiley.png'
import readreceipt from '../../Utils/Images/readreceipt.png'
import { Divider, Input, Radio } from '@mui/material';
import CustomButton from '../Commoncomponents/button';
import EmojiPicker from 'emoji-picker-react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CropperModal from './CropperModal';
import ImageIcon from '@mui/icons-material/Image';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CropIcon from '@mui/icons-material/Crop';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import man from '../../Utils/Images/man.jpg'
import waiting from '../../Utils/Images/waiting.png'
import singleTick from '../../Utils/Images/singleTick.png'
import { useDispatch, useSelector } from 'react-redux';
import { setIn } from 'formik';
import { setError } from '../../redux/slices/marketingSlice';
import { boolean } from 'yup';
import { message } from 'antd';
import {getSendMessage, getSendMessageToAll, getSendTemplate, GetStoreDetails, getTemplateList} from '../../graphql/actions'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import CustomCard from '../Commoncomponents/card';


export default function GroupChat({ open12, handleClose12,setOpen12,length,open13,setOpen13}) {
    const [open1, setOpen1] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [file, setFile] = React.useState(null);
    const [filePreview, setFilePreview] = React.useState(null)
    const [cropImage, setCropImage] = React.useState(false)
    const [open4, setOpen4] = React.useState(false)
    const [individual, setIndividual] = React.useState(false)
    const [acknowledgement, setAcknowledgement] = React.useState(false)
    const [showError, setShowError] = useState(false);
    console.log("the dispatch thing", filePreview)
    // const handleClickOpen4=()=>{
    //     setOpen4(true)
    // }
   console.log("hi",length)
    const dispatch=useDispatch();

    const { checkBoxDetails,storeData,rowDetails} = useSelector((state) => state.marketingSlice);
    console.log("rowDEtails",rowDetails)
    console.log("checkBoxDetails",checkBoxDetails.payload)
    // console.log("the dispatch thing",individual)

    useEffect(() => {
        if (checkBoxDetails && checkBoxDetails.payload || rowDetails.payload) {
          const trueCounts = rowDetails.payload ? Object.values(rowDetails.payload)?.filter(value => value === true).length : null ;
          const trueCount = checkBoxDetails.payload ? Object?.values(checkBoxDetails?.payload)?.filter(value => value === true).length : null
          const trueKeys = Object.entries(
            checkBoxDetails?.payload ? checkBoxDetails.payload : rowDetails.payload
          )
          .filter(([key, value]) => value === true)
          .map(([key]) => key);
        
      
      console.log("keys",trueCounts)
      console.log("one",trueCount)
          if (trueCount === 1 || trueCounts === 1 ) {
            setIndividual(true);
            dispatch(GetStoreDetails(trueKeys[0])); // Assuming this fetches the store details
            setShowError(false);
            
            console.log("7")
          }
        //   else if(trueCounts === 1){
        //     setIndividual(true)
        //     dispatch(GetStoreDetails(trueKeys[0]));
        //     setOpen12(false)
        //   }
        else if (trueCount === 0) {
            setShowError(true)
            setTimeout(()=>{
                setOpen12(false)
            },500)
            
        }
        else {
            setIndividual(false);
            setShowError(false)
        }
    } else {
        setShowError(true)
        setTimeout(()=>{
            setOpen12(false)
        },500)
        }
      }, [checkBoxDetails, rowDetails]); // Ensure rowDetails is included if it changes
      
  console.log("hi",length)
    useEffect(()=>{
       if(showError && length) {
        message.error({
            content: "please choose any stores",
            className: "custom-class",
            style: {
              marginTop: "10vh",
              marginLeft: "15vw",
            },
            duration: 3.5,
          });
       }
    },[showError])


    const handleClickClose4 = () => {
        setOpen4(false)
    }

    const handleCropImage = () => {
        setCropImage(true)
        setOpen4(true)
    }

    console.log("file", file)
    console.log("filePreview", filePreview)


    const handleClick2 = (event) => {
        setAnchorEl(event.currentTarget);

    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };
    const open2 = Boolean(anchorEl);


    // const handleClick3 = (event) => {
    //     setAnchorEl1(event.currentTarget);
    // };
    useEffect(() => {
        if (filePreview) {
            setAnchorEl(null)
            setAnchorEl1(true)
        }

    }, [filePreview])

    const handleClose3 = () => {
        setAnchorEl1(null);
        setFile(null)
        setFilePreview(null);
    };
    const open3 = Boolean(anchorEl1);
    const toggleDrawer = (newOpen) => () => {
        setOpen1(newOpen);
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
    const smmatch = useMediaQuery(theme.breakpoints.down("sm"))
    const upsmmatch = useMediaQuery(theme.breakpoints.up("sm"));
    const upLgmatch = useMediaQuery(theme.breakpoints.up("xl"));
    const [select, setSelect] = useState('')

    const [messages, setMessages] = useState([
        { id: 1, text: "hello", sender: "Deep", image: avatar, sent: true, bussinessType: "Restaurants" },
        { id: 2, text: "berlin", sender: "Deep", image: avatar, sent: true, bussinessType: "Hotel" },
    ]);
    const [replyContext, setReplyContext] = useState(null);
    const [newMessage, setNewMessage] = useState('')
    const [input, setInput] = useState('');
    const messagesEndRef = useRef(null);
    console.log("input",input)
    const handleEmojiClick = (emojiObject) => {
        setInput((prevMessage) => prevMessage + emojiObject.emoji);

    };

    const handleSend = () => {
        const phoneNumber = `91${storeData?.getStoreDetails?.mobileNo}`;
          const message = input; 

        dispatch(getSendTemplate(phoneNumber,selectedTemplates[0]?.templateName))
        dispatch(getSendMessageToAll())
        if (input.trim() !== '') {
          // Add the message to the local message list
          setMessages([
            ...messages,
            {
              text: input,
              sent: true,
              sender: "berlin", // Customize the sender information
              image: avatar, // Sender's avatar or image
              id: messages.length + 1, // Unique ID for each message
              businessType: "Restaurants", // Example business type
              file, // File attached to the message (if any)
              filePreview, // File preview (if any)
            },
          ]);
      
          // Clear the input and other states
          setInput('');
          setNewMessage('');
          setReplyContext(null);
          setFile(null);
          setFilePreview(null);
          setShowEmojiPicker(false);
      
          
          
          // Add country code '+91' to each number
        //   const phoneNumbersWithCountryCode = phoneNumbers.map(number => `91${number}`);
          
          //console.log(phoneNumbersWithCountryCode);
          
          dispatch(getSendMessage(phoneNumber,message));
                  }
      };
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile)
        setFilePreview(URL.createObjectURL(selectedFile))
    }

    const hnadleReply = (message) => {
        setReplyContext(message)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSend();
        }
    };

    const handleChange = (event) => {
        setSelect(event.target.value)
    }

    const handletemplateclose=()=>{
        setAnchorEl(null);
        setOpen1(false)
        toggleDrawer(false)
    }
    const StatusFilter = [
        { id: 1, label: "Accepted" },
        { id: 2, label: "rejected" },
        { id: 3, label: "completed" }
    ]
    const listData = useSelector((state) => state.marketingSlice?.listData);
    console.log("data", listData?.listTemplates);
    useEffect(()=>{
        dispatch(getTemplateList())
    },[])
    const templates = [
        { id: 1, label: "Introduction", image: Rectangle },
        { id: 2, label: "Welcome", image: Rectangle },
        { id: 3, label: "Introduction", image: Rectangle },
        { id: 4, label: "Welcome", image: Rectangle },
        { id: 5, label: "Introduction", image: Rectangle },
        { id: 6, label: "Welcome", image: Rectangle },
        { id: 7, label: "Introduction", image: Rectangle },
        { id: 8, label: "Welcome", image: Rectangle },
        { id: 9, label: "Introduction", image: Rectangle },
        { id: 10, label: "Welcome", image: Rectangle },
        { id: 11, label: "Introduction", image: Rectangle },
        { id: 12, label: "Welcome", image: Rectangle },
    ]

    const AvatarList = [
        { id: 1, image: man },
        { id: 2, image: man },
        { id: 3, image: man },
        { id: 4, image: man },
        { id: 5, image: man },
        { id: 6, image: man },
    ]

  const [selectedTemplates,setSelectedTemplates] = useState([])
  console.log("selected",selectedTemplates)
  const handleRadioChange = (id, templateName) => {
    setSelectedTemplates(prevState => {
      const newSelection = { id, templateName };
      return [...prevState.filter(item => item.id !== id), newSelection];
    });
  };
    const users = [

        { id: 1, img: man, name: "berlin", description: "this is hello world message", time: "2:00 pm" },
        { id: 2, img: man, name: "Ashiya", description: "this is hello world message", time: "2:00 pm" },
        { id: 3, img: man, name: "Celesta", description: "this is hello world message", time: "2:00 pm" },
        { id: 4, img: man, name: "Amurtha", description: "this is hello world message", time: "2:00 pm" },
        { id: 5, img: man, name: "Archana", description: "this is hello world message", time: "2:00 pm" },
    ]

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const now = new Date();
  
    // Get hours, minutes, and AM/PM
    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    // Convert hours from 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    
    // Format time
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    
    console.log("open12",open12)
    console.log("open13",open13)
    console.log("individual",individual)
    return (
        <div>
          {!showError&& ( <Dialog
                open={open12 || open13}
                onClose={handleClose12}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

                maxWidth={"1000"}>
                <DialogContent sx={{ padding: "0px", borderBottom: "1px solid #DADADA" }}>
                    <div style={{ display: "flex", gap: "10px", flexDirection: "column", overflowX: smmatch && "hidden" }}>
                        <div style={{ padding: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                {!individual ?
                                    <div style={{ marginLeft: "20px" }}>
                                        <ThemeProvider
                                            theme={createTheme({
                                                components: {
                                                    MuiAvatarGroup: {
                                                        styleOverrides: {
                                                            root: ({ ownerState: { max } }) => ({
                                                                ...[...Array(max)].reduce(
                                                                    (result, curr, index) => ({
                                                                        ...result,
                                                                        [`& > .MuiAvatar-root:nth-child(${index + 1})`]: {
                                                                            zIndex: max - index,
                                                                            marginLeft: -24 * 1,  // 60% of the avatar width (assuming 24px as the default size)
                                                                            width: matches ? 35 : 46,
                                                                            height: matches ? 35 : 46
                                                                        }
                                                                    }),
                                                                    {}
                                                                )
                                                            })
                                                        }
                                                    }
                                                }
                                            })}
                                        >
                                            <AvatarGroup max={3}>


                                                {AvatarList.map((avatar) => (
                                                    <Avatar alt="Remy Sharp" src={avatar.image} sx={{ width: matches ? 40 : 46, height: matches ? 40 : 46 }} />
                                                ))
                                                }

                                            </AvatarGroup>
                                        </ThemeProvider>
                                    </div>
                                    : <><div style={{ display: "flex", gap: '5px', alignItems: "center" }}>
                                        <div style={{ height: "10px", width: "10px", background: "#13801C", borderRadius: "50%" }}></div>
                                        <Typography className='chatFont' fontSize={contentFontSize_16} >Accepted</Typography>
                                    </div>

                                    </>}
                                <div style={{ cursor: "pointer" }} onClick={handleClose12}><img src={closeIcon} alt='closeicon' width={matches ? "13px" : "20px"} height={matches ? "13px" : "20px"} /> </div>
                            </div>



                            {individual && (<><div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: matches && "wrap" }}>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <div>
                                        {/* <img src={AS} alt='storeimage' width="91px" height="91px" style={{ borderRadius: "50%" }} />  */}
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={AS}
                                            sx={{ width: matches ? 70 : 91, height: matches ? 70 : 91 }}
                                        />
                                    </div>
                                    <div style={{ width: "400px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", gap: matches ? '1px' : '5px' }}>
                                            <Typography className='chatFontHeebo' fontSize={HeaderFontSize_26}>{storeData?.getStoreDetails?.storeName}</Typography>
                                            <Typography className='chatFontHeebo400' fontSize={contentFontSize_20}>{storeData?.getStoreDetails?.businessType?.join(", ")}</Typography>
                                            <Typography className='chatFontHeebo400Color' fontSize={contentFontsize_14_xs_10}>{storeData?.getStoreDetails?.district}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                                    <div style={{ display: 'flex', gap: smmatch ? "10px" : "20px", flexWrap: smmatch && "wrap", marginTop: matches && "10px" }}>
                                        <div style={{ display: "flex", gap: "10px", flexWrap: smmatch && "wrap" }}>
                                            <div className='centerAlign'><img src={call} alt="call image" /> </div>
                                            <Typography className='chatFontHeebo400' fontSize={contentFontSize_16}>{storeData?.getStoreDetails?.mobileNo}</Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "10px", flexWrap: smmatch && "wrap" }}>
                                            <div className='centerAlign'> <img src={mobile} alt='mobile image' /> </div>
                                            <Typography className='chatFontHeebo400' fontSize={contentFontSize_16}>{storeData?.getStoreDetails?.altMobileNo}</Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', gap: smmatch ? "10px" : "20px", flexWrap: smmatch && "wrap" }}>
                                        <div style={{ display: "flex", gap: "10px", flexWrap: smmatch && "wrap" }}>
                                            <div className='centerAlign'> <img src={whatsappImg} alt='whatsapp image' /> </div>
                                            <Typography className='chatFontHeebo400' fontSize={contentFontSize_16}>{storeData?.getStoreDetails?.whatsappNo}</Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "10px", flexWrap: smmatch && "wrap" }}>
                                            <div className='centerAlign'> <img src={email} alt="email image" /> </div>
                                            <Typography className='chatFontHeebo400' fontSize={contentFontSize_16}>{storeData?.getStoreDetails?.emailId}</Typography>
                                        </div>
                                    </div>

                                </div>

                            </div>
                                <div className='chatSelect'>
                                    <Box sx={{ minWidth: smmatch ? 1 : 200 }}>
                                        <FormControl fullWidth>

                                            <Select
                                                sx={{
                                                    borderRadius: "10px",
                                                    background: "#0D75BA",
                                                    color: "white",
                                                    height: matches ? "36px" : "42px",
                                                    width: "100%",
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#0D75BA',

                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#0D75BA',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#0D75BA',
                                                    },
                                                }}

                                                displayEmpty

                                                value={select}

                                                onChange={handleChange}
                                                renderValue={(selected) => {

                                                    if (!selected) {
                                                        return <Typography style={{ fontFamily: "Heebo", fontWeight: "400", color: "white" }} fontSize={contentFontSize_16}>Select</Typography>;
                                                    }
                                                    return <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>{selected}</Typography>;
                                                }}

                                            >
                                                {StatusFilter.map((item) => (
                                                    <MenuItem key={item.id} value={item.label}>
                                                        <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>
                                                            {item.label}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </>)
                            }


                            {/* {smmatch && <div style={{ marginTop: "10px" }}> <CustomButton background="#0D75BA" onClick={toggleDrawer(true)}><Typography style={{ fontFamily: "Heebo", fontWeight: "400", textTransform: "capitalize" }} fontSize={contentFontSize_16}>Templates</Typography></CustomButton></div>} */}
                            <Dialog open={open1} onClose={handletemplateclose}>
                                <div style={{ margin: '0px 0px' }}>
                                    <div style={{ textAlign: "center", padding: "20px" }}>
                                        <Typography className='chatFont' fontSize={contentFontSize_20}>Templates</Typography>

                                    </div>
                                    <div style={{height:"500px",overflowY:"scroll"}}>
                                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap", width: matches ? "230px" : "300px", padding: "20px"}}>
                                    
                                        {/* {listData?.listTemplates.map((template) => (
                                            
                                            <div style={{ display: "flex", gap: "10px", flexBasis: "calc(50% - 10px)", boxSizing: "border-box" }} key={template.id}>
                                                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                    <img src={template.image} alt={template.label} style={{ width: matches ? "90px" : "124px", height: matches ? "110px" : "146px" }} />
                                                    <Typography className='chatFontCenter' fontSize={HeaderNavigationFontSize_12}>{template.label}</Typography>
                                                </div>
                                            </div>
                                           
                                        ))} */}
                                         {listData?.listTemplates?.map((item) => (
  <div
    key={item.id}
    style={{
      display: "flex",
      flexDirection: lgmatch ? "column" : "row",
      justifyContent: matches ? "center" : "space-evenly",
      marginTop: "20px",
      alignItems: "center",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "40px",
      }}
    >
      <>
        <CustomCard
          width="170px"
          height="100%"
          padding="10px 30px 80px 10px"
        >
          <div>
            <Typography>{item?.header?.content}</Typography>
          </div>
          <div>
            <Typography>{item?.body}</Typography>
          </div>
          <div style={{ marginTop: "15px" }}>
            <CustomButton background="#0D75BA">
              <Typography style={{ textTransform: "capitalize" }}>
                {item.button}
              </Typography>
            </CustomButton>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Typography>{item.footer}</Typography>
          </div>
        </CustomCard>

        <Typography
          style={{ textAlign: "center", marginTop: "10px" }}
        >
          {item.templateName}
        </Typography>
      </>
    </div>
    <Radio
      checked={selectedTemplates === item.id}
      onChange={() => setSelectedTemplates(item.id,item.templateName)}
      value={item.id}
      name="template-radio-button"
      inputProps={{ 'aria-label': item.templateName }}
    />
  </div>
))}

                                      
                                    </div>
                                    </div>

                                </div>
                            </Dialog>
                        </div>





                        <div style={{ display: "flex", borderTop: "1px solid #DADADA" }}>
                            {upsmmatch && (<div style={{ margin: '0px 0px' }}>
                                <div style={{ textAlign: "center", padding: "20px" }}>
                                    <Typography className='chatFont' fontSize={contentFontSize_20}>Templates</Typography>

                                </div>

                                <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap", width: matches ? "230px" : "300px", padding: "20px", overflowY: "scroll", height: "400px", msOverflowStyle: "none", scrollbarWidth: "none" }}>
                                {listData?.listTemplates?.map((item) => (
  <div
    key={item.id}
    style={{
      display: "flex",
      flexDirection: lgmatch ? "column" : "row",
      justifyContent: matches ? "center" : "space-evenly",
      marginTop: "20px",
      alignItems: "center",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "40px",
      }}
    >
      <>
        <CustomCard
          width="170px"
          height="100%"
          padding="10px 30px 80px 10px"
        >
          <div>
            <Typography>{item?.header?.content}</Typography>
          </div>
          <div>
            <Typography>{item?.body}</Typography>
          </div>
          <div style={{ marginTop: "15px" }}>
            <CustomButton background="#0D75BA">
              <Typography style={{ textTransform: "capitalize" }}>
                {item.button}
              </Typography>
            </CustomButton>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Typography>{item.footer}</Typography>
          </div>
        </CustomCard>

        <Typography
          style={{ textAlign: "center", marginTop: "10px" }}
        >
          {item.templateName}
        </Typography>
      </>
    </div>
    <Radio
            checked={selectedTemplates.some(template => template.id === item.id)}
            onChange={() => handleRadioChange(item.id, item.templateName)}
            value={item.id}
            name="template-radio-button"
            inputProps={{ 'aria-label': item.templateName }}
          />
  </div>
))}
                                </div>

                            </div>)}


                            <div style={{ width: "656px", height: "516px", borderLeft: "1px solid #DADADA" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                                    <div style={{ width: "100%", height: "68px", borderBottom: "1px solid #E5E5EA", padding: "0px 10px" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", height: "100%" }}>
                                            {/* <img src={avatar} alt='profile image' width="24" height="24" style={{ borderRadius: "50%" }} /> */}
                                            {individual ? <Avatar
                                                alt="Remy Sharp"
                                                src={avatar}
                                                sx={{ width: 24, height: 24 }}
                                            /> : <div></div>}
                                            <div style={{alignItems:"center",width:"100%"}}>
                                            <div style={{ display: "flex", gap: "3px", flexDirection: "column",justifyContent:"center",alignItems:"center" }}>
                                                <Typography style={{ fontFamily: "Inter", fontWeight: "700", textAlign: "center" }} fontSize={contentFontSize_20}>   {individual ? <>{storeData?.getStoreDetails?.storeName}</> : <>Messengers</>}</Typography>
                                                <Typography className='chatFontHeebo400Color' fontSize={HeaderNavigationFontSize_12} style={{ textAlign: "center" }}>  {individual ? <>last seen 45 minutes ago</> : <>Active</>}</Typography>
                                            </div>
                                            </div>
                                            {/* <img src={threedotHori} alt='threedot' width="16" height="3" style={{marginRight:"30px"}} /> */}

                                        </div>



                                                                            </div>
                                    <div className="messages">
                                    <Typography
                                        style={{
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        color: '#666668',
                                        position: 'sticky',
                                        top: '0px',
                                        zIndex: '10',
                                        textAlign: 'center', // Center the text within the container
                                        }}
                                        fontSize={HeaderNavigationFontSize_12}
                                    >
                                        8/20/2020
                                    </Typography>
                                        {!acknowledgement ? (messages.map((message, index) => (
                                            <div key={index} className="message" style={{ display: "flex", justifyContent: message.sender === "berlin" ? "flex-end" : "flex-start", width: message.sender === "berlin" ? "100%" : "80%" }}>
                                                <div style={{ display: "flex", gap: "10px", width: "100%", justifyContent: message.sender === "berlin" ? "flex-end" : "flex-start" }}>
                                                    {message.sender !== "berlin" && <Avatar src={message.image} alt="avatar" sx={{ width: 24, height: 24 }} />}
                                                    <div style={{ maxWidth: "50%", minWidth: "30%", height: "100%", background: message.sender === "berlin" ? "#007AFF" : "#F2F2F7", color: message.sender === "berlin" ? "white" : "black", borderRadius: "6px", padding: matches ? "10px 10px 15px 10px" : "10px 10px 25px 10px", position: "relative", wordWrap: "break-word", wordBreak: "break-word" }}>
                                                        <Typography style={{ width: "100%", height: "100%", fontFamily: "Inter", fontWeight: "400" }} fontSize={contentFontsize_14}>{message.text}</Typography>
                                                        {message.sender !== "berlin" && <Typography style={{ fontFamily: "Inter", fontWeight: "400", color: "#666668", position: "absolute", top: "1px", left: smmatch ? "20%" : "30%" }} fontSize={HeaderNavigationFontSize_12}>{message.bussinessType}</Typography>}
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", gap: "5px", position: "absolute", bottom: "10px", alignItems: "center", right: message.sender === "berlin" && "15px", left: message.sender !== "berlin" && (smmatch ? "30%" : "20%") }}>
                                                    <Typography className='chatFontHeebo400' style={{ color: message.sender === "berlin" ? "white" : "#666668" }} fontSize={HeaderNavigationFontSize_12}>{formattedTime}</Typography>
                                                    {message.sender === "berlin" && <img src={readreceipt} alt='read receipt' width="16px" height="8px" />}

                                                </div>


                                            </div>
                                        ))) : (
                                            <>
                                                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                    {users.map((user) => (
                                                        <div key={user.id}>
                                                            <div style={{ padding: "30px" }}>
                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

                                                                        <Avatar
                                                                            alt={user.img}
                                                                            src={avatar}
                                                                            sx={{ width: matches ? 35 : 50, height: matches ? 35 : 50 }}
                                                                        />
                                                                        <div style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
                                                                            <Typography className='chatFontHeebo' fontSize={contentFontSize_20}>{user.name}</Typography>
                                                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                                <div><img src={singleTick} alt='single tick' width={matches ? "12px" : "15px"} height={matches ? "9px" : "12px"} /></div>
                                                                                <Typography className='chatFontHeebo400Gray' fontSize={contentFontSize_16}>{user.description}</Typography>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <Typography className='chatFontHeebo400Gray' fontSize={contentFontSize_16}>{user.time}</Typography>
                                                                </div>
                                                            </div>
                                                            <Divider />
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                        <div ref={messagesEndRef} />

                                    </div>
                                    <div style={{ width: "100%", borderTop: "1px solid #E5E5EA" }}>

                                        <TextField
                                            variant="outlined"
                                            placeholder='Start Typing....'
                                            multiline

                                            // rows='1'
                                            value={input}
                                            name="Search"
                                            onChange={(e) => setInput(e.target.value)}
                                            onKeyDown={handleKeyPress}
                                            InputProps={{

                                                endAdornment: (
                                                    <InputAdornment position="start" sx={{ width: '89px' }}>
                                                        <div style={{ display: "flex", alignItems: "center", width: "100%", height: "100%", gap: '20px' }}>
                                                            {/* <div onClick={handleClick2} ><img src={Upload} alt='upload image' style={{ cursor: "pointer" }} /> </div> */}
                                                            <div onClick={handleSend}> <img src={send} alt='send icon' style={{ cursor: "pointer",marginLeft:"54px" }} /> </div>
                                                        </div>


                                                    </InputAdornment>
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position='start' sx={{ width: '30px' }}>
                                                        <div style={{ display: "flex", alignItems: "center" }} onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                                                            <img src={smiley} alt='smiley icon' style={{ cursor: "pointer" }} />
                                                        </div>
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    fontSize: matches && '13px',
                                                    // height: matches ? "40px" : "48x",
                                                    // background: "white",
                                                },
                                            }}

                                            sx={{
                                                width: '100%',

                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '16px',
                                                    '& fieldset': {
                                                        borderColor: 'white', // This will change the border color
                                                        borderTop: "black"
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'white', // This will change the border color on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'white', // This will change the border color when focused
                                                    },
                                                },

                                                '& .MuiInputBase-input': {
                                                    backgroundColor: 'white',
                                                },
                                            }}


                                        />



                                    </div>
                                </div>

                            </div>
                        </div>
                        {showEmojiPicker && (
                            //   <Box sx={{ position: 'absolute', bottom: '60px', right: '20px' }}>
                            //     <EmojiPicker onEmojiClick={handleEmojiClick} />
                            //   </Box>
                            <Box sx={{ position: 'absolute', bottom: '60px', right: '20px', width: smmatch ? "210px" : '250px', height: '300px', overflow: 'hidden' }}>
                                <EmojiPicker onEmojiClick={handleEmojiClick} pickerStyle={{ width: '100%', height: '100%' }} />
                            </Box>
                        )}

                    </div>
                </DialogContent>
            </Dialog>)}

            <Popover

                open={open2}
                anchorEl={anchorEl}
                onClose={handleClose2}

                anchorOrigin={{
                    vertical: 800,
                    horizontal: 400,

                }}
                transformOrigin={{
                    vertical: 500,
                    horizontal: 500,
                }}
                PaperProps={{
                    style: {
                        marginTop: '-30px', // Adjust this value as needed to move the popover up
                    }
                }}
            >
                <div>
                    <input
                        accept='image/*'
                        id="image-input"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <input
                        accept='video/*'
                        id="video-input"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <input
                        accept='application/pdf/*'
                        id="file-input"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <input
                        accept='audio/*'
                        id="audio-input"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <div style={{ width: "100%", height: "100%", display: "flex", gap: "10px", flexDirection: "column", padding: "20px" }}>
                        <div><label htmlFor='image-input'>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}>

                                <ImageIcon sx={{ color: "#0D75BA", width: matches ? "20px" : "25px", height: matches ? "20px" : "25px" }} />

                                <Typography className='chatFontHeebo400Color' fontSize={contentFontSize_16}>Images</Typography>
                            </div>

                        </label> </div>
                        <div> <label htmlFor='video-input'>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}>
                                <OndemandVideoIcon sx={{ color: "#0D75BA", width: matches ? "20px" : "25px", height: matches ? "20px" : "25px" }} />
                                <Typography className='chatFontHeebo400Color' fontSize={contentFontSize_16}>Video</Typography>
                            </div>
                        </label></div>
                        <div> <label htmlFor='audio-input'>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}>
                                <AudiotrackIcon sx={{ color: "#0D75BA", width: matches ? "20px" : "25px", height: matches ? "20px" : "25px" }} />
                                <Typography className='chatFontHeebo400Color' fontSize={contentFontSize_16}>Audio</Typography>
                            </div>
                        </label></div>
                        <div> <label htmlFor='file-input'>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}>
                                <DocumentScannerIcon sx={{ color: "#0D75BA", width: matches ? "20px" : "25px", height: matches ? "20px" : "25px" }} />
                                <Typography className='chatFontHeebo400Color' fontSize={contentFontSize_16}>Documents</Typography>
                            </div>
                        </label></div>
                     {smmatch&&  ( <div style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }} onClick={toggleDrawer(true)}>
                                <AutoAwesomeMotionIcon sx={{ color: "#0D75BA", width: matches ? "20px" : "25px", height: matches ? "20px" : "25px" }} />
                                <Typography className='chatFontHeebo400Color' fontSize={contentFontSize_16}>Templates</Typography>
                            </div>)}
                    </div>
                </div>
            </Popover>

            <Popover

                open={open3}
                anchorEl={anchorEl1}


                // anchorOrigin={{
                //     vertical: 1240,
                //     horizontal: 1490,

                // }}
                // transformOrigin={{
                //     vertical: 500,
                //     horizontal: 500,
                // }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: 1200,

                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        marginTop: '-30px', // Adjust this value as needed to move the popover up
                    }
                }}
            >
                <div style={{ width: "100%", height: "100%", padding: "10px 10px 0px 10px", position: "relative" }}>
                    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        {file && file.type.startsWith('image/') && (
                            <>

                                <div onClick={handleCropImage} style={{ textAlign: "left", width: "100%", cursor: 'pointer' }}>
                                    <CropIcon sx={{ color: "#0D75BA", height: matches ? "20px" : "25px", width: matches ? "20px" : "25px" }} />
                                </div>
                                <img src={filePreview} alt="image" width={matches ? "90px" : "130px"} height={matches ? "90px" : "130px"} />
                            </>
                        )}
                        {file && file.type.startsWith('video/') && (
                            <video controls style={{ width: matches ? "90px" : "130px", height: matches ? "90px" : "130px" }}>
                                <source src={filePreview} type={file.type} />
                            </video>
                        )}
                        {file && file.type.startsWith('audio/') && (
                            <div style={{ height: "150px", display: "flex", alignItems: "center" }}>
                                <audio controls style={{ width: matches ? "100px" : "130px" }}>
                                    <source src={filePreview} type={file.type} />
                                </audio>
                            </div>


                        )}
                        {file && file.type.startsWith('application/') && (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <InsertDriveFileIcon style={{ fontSize: matches ? "50px" : "70px" }} />
                                <Typography style={{ marginTop: "10px", textAlign: "center" }} fontSize={contentFontSize_16}>
                                    {file.name}
                                </Typography>
                                <a href={filePreview} target="_blank" rel="noopener noreferrer" style={{ marginTop: "10px", color: "#0D75BA", fontSize: "14px" }}>
                                    Open
                                </a>
                            </div>
                        )}
                        <div style={{ marginTop: "10px", textAlign: "right", width: "100%" }}>
                            <SendIcon sx={{ color: "#0D75BA", height: matches ? "20px" : "25px", width: matches ? "20px" : "25px" }} />
                        </div>
                        <div style={{ margin: "5px" }}>
                            <Typography onClick={handleClose3} style={{ color: "#0D75BA", fontFamily: "Inter", fontWeight: "bold", cursor: "pointer", position: "absolute", top: "10px", right: "7px" }} fontSize={contentFontsize_14_xs_10}>
                                <CloseIcon sx={{ height: matches ? "20px" : "25px", width: matches ? "20px" : "25px", color: "black" }} />
                            </Typography>
                        </div>
                    </div>
                </div>
            </Popover>

            {cropImage && (
                <Dialog

                    open={open4}
                    onClose={handleClickClose4}
                >

                    <DialogContent>


                        <CropperModal setFile={setFile} setFilePreview={setFilePreview} file={file} filePreview={filePreview} setOpen4={setOpen4} />
                    </DialogContent>
                </Dialog>
            )}


        </div>
    )
}
