import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, MenuIcon } from './MUIcomponents/index';
import { Box } from './MUIcomponents/index';
import { CssBaseline } from './MUIcomponents/index';
import { Drawer } from './MUIcomponents/index';
import { IconButton } from './MUIcomponents/index';
import { List } from './MUIcomponents/index';
import { ListItem } from './MUIcomponents/index';
import { ListItemButton } from './MUIcomponents/index';
import { ListItemIcon } from './MUIcomponents/index';
import { ListItemText } from './MUIcomponents/index';
import { Toolbar, useTheme, useMediaQuery } from './MUIcomponents/index';
import { Typography } from './MUIcomponents/index';
import { Avatar } from './MUIcomponents/index';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from '../Utils/Images/DashboardIcon.svg';
import { ReactComponent as ReportIcon } from '../Utils/Images/ReportIcon.svg';
import { ReactComponent as StoreIcon } from '../Utils/Images/StoreIcon.svg';
import { ReactComponent as TemplateIcon } from '../Utils/Images/TemplateIcon.svg';
import { contentFontSize_16_xs_10, HeaderFontSize_26 } from './Typography/index';
import { Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { getLogOut, getToken } from '../graphql/actions';
import { useDispatch, useSelector } from 'react-redux';
import { resetLogOutData, setLogoutSuccess } from '../redux/slices/marketingSlice';
import CustomDialog from "../components/Commoncomponents/dialog"
import CustomButton from './Commoncomponents/button';

function ResponsiveDrawer(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const open = Boolean(anchorEl);
  

  



  const nav = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const drawerWidth = matches ? 270 : 320;

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
    //dispatch(getToken(localStorage.getItem('refreshToken')))
  };


  const handleNavigate = (text) => {
    if (text === 'Dashboard') {
      nav('/');
    } else if (text === 'Store') {
      nav('/store');
    } else if (text === 'Templates') {
      nav('/template');
      localStorage.removeItem("array")
    } else if (text === 'Reports') {
      nav('/report');
    }
    // else{
    //   nav('/login')
    // }
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
   // dispatch(getToken(localStorage.getItem('refreshToken')))
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleMenuClose();
    setDialogOpen(true);
    //dispatch(getToken(localStorage.getItem('refreshToken')))
  };
  
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const dispatch = useDispatch();
  const logOutData = useSelector(state => state.marketingSlice?.logOutData);



  // useEffect(()=>{
  //   dispatch(getToken(localStorage.getItem('refreshToken')))
  // },[dispatch])






  const handleLogout = () => {
    const token = localStorage.getItem("refreshToken");
    dispatch(getLogOut(localStorage.getItem("refreshToken")))
    
    
       
  
  };
  const logoutSuccess = useSelector(
    (state) => state.marketingSlice.logoutSuccess
  );
  console.log("logoutSuccess",logoutSuccess)
  useEffect(()=>{
    if(logoutSuccess){
      handleDialogClose();
      setTimeout(() => {
        
        dispatch(setLogoutSuccess());
        nav("/login")
      }, 1000);
    }
},[logoutSuccess])



  // useEffect(() => {
  //   if (logOutData) {
  //     nav("/login");
  //     dispatch(resetLogOutData()); // Reset logOutData after logout
  //   }
  // }, [logOutData, nav, dispatch]);
  

  //  useEffect(()=>{
  //     if(logOutData && redirectToLogin){
  //       nav("/login")
  //     }             
  //  },[logOutData, redirectToLogin])

  // useEffect(() => {
  //   if (logOutData && !redirectToLogin) {
     
  //     setRedirectToLogin(true);
  //     // if (location.pathname !== "/login") {
  //       nav("/login");
  //     }
  //     dispatch(resetLogOutData());
  //   }
  // }, [logOutData, location.pathname, nav, dispatch, redirectToLogin]);

  const drawerItems = [
    { text: "Dashboard", path: '/', icon: DashboardIcon },
    { text: "Store", path: '/store', icon: StoreIcon },
    { text: "Templates", path: '/template', icon: TemplateIcon },
    { text: "Reports", path: '/report', icon: ReportIcon },
  ];

  const drawer = (
    <div style={{ padding: "50px 50px 50px 20px" }}>
      <div style={{ display: "flex", gap: "10px" }}>
        <Typography style={{ fontFamily: "Poppins", fontWeight: "700", color: "#0D75BA" }} fontSize={HeaderFontSize_26}>MARKETING </Typography>
        <Typography style={{ fontFamily: "Poppins", fontWeight: "400", color: "#0D75BA" }} fontSize={HeaderFontSize_26}>PORTAL</Typography>
      </div>
      <List sx={{ marginTop: "30px", paddingRight: matches ? "0px" : "40px" }}>
        {drawerItems.map((item) => (
          <ListItem key={item.text} disablePadding onClick={() => handleNavigate(item.text)}>
            <ListItemButton
              sx={{
                backgroundColor: location.pathname === item.path ? '#0D75BA' : 'inherit',
                color: location.pathname === item.path ? 'white' : '#7C7B7B',
                borderRadius: "24px",
                marginTop: "20px",
                '&:hover': {
                  backgroundColor: location.pathname === item.path ? '#0D75BA' : '#f5f5f5',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  width: matches ? "15px" : "20px",
                  height: matches ? "15px" : "20px",
                  color: location.pathname === item.path ? 'white' : '#7C7B7B',
                }}
              >
                <item.icon style={{ fill: location.pathname === item.path ? 'white' : '#7C7B7B', width: matches ? "15px" : "20px", height: matches ? "15px" : "20px" }} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{
                sx: {
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: matches ? "12px" : "15px"
                }
              }} primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: '#fff',
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            color="Black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Avatar alt="User Avatar" src="/path-to-avatar.jpg" onClick={handleAvatarClick} />
          <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem 
          onClick={handleLogoutClick} 
          sx={{ 
            height: 80,  // Adjust the height
            width: 200   // Adjust the width
          }}
        >
          Logout
        </MenuItem>
      </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` }, overflowX: "auto" }}
      >
        <Toolbar />
        {children}
      </Box>

      {/* Confirmation Dialog */}
      <CustomDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        top="20px"
        right="20px"
        width="400px"
        height="600px"
        borderRadius="25px"
      >
        <DialogTitle >
          <Typography sx={{color:"black",fontWeight:"700",fontSize:"22px"}}>
          Confirm Logout
          </Typography></DialogTitle>
        <DialogContent>
          <Typography sx={{color:"black",fontWeight:"400",fontSize:"18px"}}>Are you sure you want to logout?</Typography>
        </DialogContent>
        <DialogActions>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginRight:"70px",gap:"20px"}}>
            <div>
          <CustomButton onClick={handleLogout} color="white" background="#0D75BA" width="100px" height="40px">
            Yes
          </CustomButton>
          </div>
          <div>
          <CustomButton onClick={handleDialogClose} color="white" background="#0D75BA" width="100px" height="40px">
            No
          </CustomButton>
          </div>
          </div>
        </DialogActions>
      </CustomDialog>

{/* {open &&  ( */}
  {/* <Dialog
  open={dialogOpen}
>
  <DialogContent>
    <div style={{ height: "100%", width: "100%", padding: matches ? "10px" : "15px" }}>
      <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <Typography fontSize={contentFontSize_16_xs_10} className='chatFontHeebo400Color'>Are you sure you want to logout?</Typography>

        <div style={{ display: "flex", gap: matches ? "10px" : "15px", textAlign: "center", justifyContent: "space-evenly" }}>
          <Typography fontSize={contentFontSize_16_xs_10} onClick={handleDialogClose} className='chatFontHeebo400Color' style={{ cursor: "pointer" }}>No</Typography>
          <Typography fontSize={contentFontSize_16_xs_10}>|</Typography>
          <Typography fontSize={contentFontSize_16_xs_10} onClick={handleLogout} className='chatFontHeebo400Red' style={{ cursor: "pointer" }}>Yes</Typography>
        </div>

      </div>

    </div>
  </DialogContent>
</Dialog> */}
{/* )} */}

    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
  children: PropTypes.node,
};

export default ResponsiveDrawer;
